import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse, Navbar, NavbarToggler, Nav,
} from 'reactstrap';
import {
  faTachometerAlt,
  faPaperPlane,
  faRss,
  faUsers,
  faFileAlt,
  faUserCog,
  faPaintBrush,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import Button from './Button';
import { hasAccess } from '../Containers/LoginContainer';
import { deauth } from '../Utils/auth';

const navItems = [
  { icon: faTachometerAlt, title: 'Dashboard', to: '/dashboard' },
  { icon: faPaperPlane, title: 'Sender', to: '/sender' },
  { icon: faUsers, title: 'Users', to: '/users' },
  { icon: faRss, title: 'Channels', to: '/channels' },
  { icon: faFileAlt, title: 'File Manager', to: '/files' },
  { icon: faPaintBrush, title: 'Styles', to: '/styles' },
  { icon: faUserCog, title: 'Admin', to: '/admin' },
];

const renderNavItems = (isSidebar: boolean, decodedPermissions: string[], toggleNavbar?: () => void) => {
  const navList = navItems.map((navItem) => {
    const displayLink = hasAccess(navItem.to, decodedPermissions);
    if (isSidebar) {
      if (!displayLink) return null;
      return (
        <li
          key={navItem.to}
        >
          <Link
            to={navItem.to}
            href={navItem.to}
            data-toggle="tooltip"
            data-placement="top"
            title={navItem.title}
          >
            <div className="row no-gutters">
              <div className="col-2">
                <FontAwesomeIcon icon={navItem.icon} />
              </div>
            </div>
          </Link>
        </li>
      );
    }
    if (!displayLink) return null;
    return (
      <li
        key={navItem.to}
      >
        <Link to={navItem.to} href={navItem.to} onClick={toggleNavbar}>
          {navItem.title}
        </Link>
      </li>
    );
  });
  return <>{navList}</>;
};

export const fetchPermissions = (): string[] => {
  const decodedPermissions: string | null = sessionStorage.getItem('permissions');
  let fetchedPermissions: string[];
  if (decodedPermissions !== null) fetchedPermissions = JSON.parse(decodedPermissions) as string[]; 
  else fetchedPermissions = [''];
  return fetchedPermissions;
};

export const NavigationBar = (): JSX.Element => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const dispatch = useDispatch();
  const parsedPermissions = fetchPermissions();
  const handleLogout = () => {
    deauth(dispatch);
    return window.location.assign('/login');
  };

  return (
    <>
      <Navbar color="faded" light className="hideOnDesktop navbarValcom">
        <div className="mr-auto">
          <div className="logoVMobile">
            V
          </div>
          <div className="logoAlertMobile">Alert</div>
        </div>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            {renderNavItems(false, parsedPermissions, toggleNavbar)}
            <Button className="navbar-button" text="Logout" onClick={() => handleLogout()} />
          </Nav>
        </Collapse>
      </Navbar>

      <nav aria-label="top navbar" className="navbar navbar-light hideOnPhones">
        <span className="navbar-brand mb-0 h1" />
        <form className="form-inline my-2 my-lg-0">
          <Button className="navbar-button" text="Logout" onClick={() => handleLogout()} />
        </form>
      </nav>
    </>
  );
};

export const Sidebar = (): JSX.Element => {
  const parsedPermissions = fetchPermissions();
  return (
    <div role="banner" aria-label="V-Alert Logo" className="sidebar">
      <div className="logo-v">
        V
      </div>
      <div className="logo-alert">Alert</div>
      <ul>{renderNavItems(true, parsedPermissions)}</ul>
    </div>
  );
};
