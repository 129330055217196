import axios from 'axios';

const deviceRoute = '/api/v2/device/devices';

export const fetchRegisteredDevices = async (dispatch: (action: { type: string, payload: number }) => void): Promise<boolean> => {
  const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` } };
  try {
    const res = await axios.get(deviceRoute, config);
    if (res.status === 200) {
      dispatch({ type: 'REGISTERED_DEVICES', payload: res.data.length as number });
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
};
