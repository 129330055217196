import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../Redux/reducers';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import Status from '../../Components/Status';
import AddEditUserLogic from './AddEditUserLogic';
import { getRoles, getAccessControls } from '../Admin/adminLogic';
import channelLogic from '../Channels/channelLogic';
import userLogic from './usersLogic';
import { Role, AccessControls } from '../../Typings/adminTypes';
import { Channel } from '../../Typings/channelTypes';
import { User, UserChannel } from '../../Typings/userTypes';

const usernameExists = (id: string | undefined, username: string, users: User[]) => {
  if (!users || !users.length) {
    return false;
  }

  for (let i = 0; i < users.length; i += 1) {
    if (users[i].username === username) {
      if (id) {
        if (id && users[i]._id !== id) { // if the username matches another user, but the id's also match then this user is just being edited 
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
};

export const validateUser = (
  username: string, 
  password: string, 
  confirmedPassword: string, 
  roleStateName: string, 
  id: string | undefined,
  users: User[],
):boolean => {
  const testUserOnlySpace = /[a-zA-Z0-9]/.test(username); 
  const testPassOnlySpace = /[a-zA-Z0-9]/.test(password);
  if (id === undefined) {
    if (!testPassOnlySpace) return true;
    if (password === undefined || password === null || password === '' || password.length < 8) return true;
  }
  if (!testUserOnlySpace) return true;
  if (username === undefined || username === null || username === '') return true; 
  if (roleStateName === 'Select A Role') return true;
  if (password !== confirmedPassword) return true;
  if (usernameExists(id, username, users)) return true;
  return false;
};

const AddEditUser = ({ id, toggle }: { toggle: () => void, id?: string }): JSX.Element => {
  const [errorState, setErrorState] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [roleState, setRoleState] = useState({ id: '', name: 'Select A Role' });
  const { roles, accessControls, rolesVisited } = useSelector((state: RootState) => state.admin);
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const { users, usersVisited } = useSelector((state: RootState) => state.users);
  const { channels, channelsVisited } = useSelector((state: RootState) => state.channel);
  const [channelState, setChannelState] = useState<Array<string>>([]);
  const [accessControlState, setAccessControlState] = useState<Array<string>>([]);
  const [canEditUsername, setCanEditUsername] = useState(true);
  const dispatch = useDispatch();
  const [requiredUsernameInput, setRequiredUsernameInput] = useState(false);
  const [requiredPasswordInput, setRequiredPasswordInput] = useState(false);
  const [requiredConfirmedPasswordInput, setRequiredConfirmedPasswordInput] = useState(false);
  const [requiredRoleInput, setRequiredRoleInput] = useState(false);

  useEffect(() => {
    if (id && (!usersVisited || !users.length)) {
      userLogic.getUsers(dispatch);
    }
    if (!rolesVisited || !roles.length) {
      getRoles(dispatch);
    }
    if (!channelsVisited || !channels.length) {
      channelLogic.getChannels(dispatch);
    }
    if (!accessControls.length) {
      getAccessControls(dispatch);
    }
  }, []);

  useEffect(() => {
    const editingUser = users.find((cur: User) => cur._id === id);
    if (editingUser !== undefined) {
      setUsername(editingUser.username);
      if (editingUser.username === 'GUEST' || editingUser.username === 'admin') setCanEditUsername(false);
      if (editingUser.email === null) setEmail('');
      else setEmail(editingUser.email);
      setIsChecked(editingUser.admin);
      const userRole = roles.find((r: Role) => r._id === editingUser.role);
      if (userRole) setRoleState({ id: userRole._id, name: userRole.name });
      const userChan: string[] = [];
      editingUser.channels.map((c: UserChannel) => userChan.push(c.channel._id));
      setChannelState(userChan);
      setAccessControlState(editingUser.accessControls);
    }
  }, [id, users, roles]);

  const renderRolesItems = (): JSX.Element => {
    const roleItems = roles.map((role: Role) => (
      <button
        key={role._id}
        onClick={() => {
          setRoleState({ id: role._id, name: role.name });
          setRequiredRoleInput(false);
        }}
        className="dropdown-item" 
        type="button"
      >
        {role.name}
      </button>
    ));
    return <>{roleItems}</>;
  };
  
  const renderChannelItems = (itemType:string): JSX.Element | null => {
    if (!channels.length) return null;
    const items = channels.map((chan: Channel) => (
      <div className="form-check" key={chan._id}>
        <input  
          className="checkbox"
          type="checkbox"
          id={chan._id}
          checked={AddEditUserLogic.isItemChecked(chan, itemType, channelState, accessControlState)}
          onChange={() => AddEditUserLogic.arrayofChannels(chan, channelState, setChannelState)}
          aria-label={AddEditUserLogic.isItemChecked(chan, itemType, channelState, accessControlState)
            ? `Do you want to uncheck ${chan.name} channel for this user?`
            : `Do you want to check ${chan.name} channel for this user?`}
        />
        <label htmlFor="admin" className="form-checkbox">{chan.name}</label>  
      </div>
    ));
    return <>{items}</>;
  };

  const renderAccessControls = (itemType:string): JSX.Element | null => {
    if (!accessControls.length) return null;
    const items = accessControls.map((item: AccessControls) => (
      <div className="form-check" key={item._id}>
        <input  
          className="checkbox"
          type="checkbox"
          id={item._id}
          checked={AddEditUserLogic.isItemChecked(item, itemType, channelState, accessControlState)}
          onChange={() => AddEditUserLogic.arrayofAccessControls(item, accessControlState, setAccessControlState)}
          aria-label={AddEditUserLogic.isItemChecked(item, itemType, channelState, accessControlState)
            ? `Do you want to uncheck ${item.name} access control for this user?`
            : `Do you want to check ${item.name} access control for this user?`}
        />
        <label htmlFor="admin" className="form-checkbox">{item.name }</label>  
      </div>
    ));
    return <>{items}</>;
  };

  const watchUsernameEdit = (usernameEdit: string) => {
    setUsername(usernameEdit);
    if (usernameExists(id, usernameEdit, users)) {
      setErrorState('A user with this username already exists');
    } else {
      setErrorState('');
    }
  };

  const handleUsernameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    watchUsernameEdit(e.target.value);
    if (e.target.value === '') setRequiredUsernameInput(true);
    if (e.target.value !== '') setRequiredUsernameInput(false);
  };

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setErrorState('');
    if (e.target.value === '') setRequiredPasswordInput(true);
    if (e.target.value !== '') setRequiredPasswordInput(false);
    if (e.target.value.length < 8) {
      setRequiredPasswordInput(true);
    }
  };

  const handleConfirmedPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmedPassword(e.target.value);
    if (e.target.value === '') setRequiredConfirmedPasswordInput(true);
    if (e.target.value !== '' && password !== e.target.value) {
      setRequiredConfirmedPasswordInput(true);
      setErrorState('Passwords do not match.');
    }
    if (e.target.value !== '' && password === e.target.value) {
      setRequiredConfirmedPasswordInput(false);
      setErrorState('');
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    if (id) {
      AddEditUserLogic.submitEditUser(
        e, id, channelState, channels, isChecked, accessControlState, roleState, username, password, email, toggle, dispatch, setErrorState, socket,
      );
    } else {
      AddEditUserLogic.submitAddUser( 
        e, 
        setErrorState, 
        username, 
        email, 
        password, 
        roleState, 
        channelState, 
        channels, 
        accessControlState, 
        accessControls, 
        isChecked, 
        dispatch, 
        toggle, 
        socket,
      );
    }
  };
  
  return (
    <main className="outerFormContainer">
      { errorState.length ? <Status type="error" message={errorState} /> : null }
      <div className="form-group requiredFormGroupLabel">
        <Input 
          disabled={!canEditUsername}
          htmlFor="Username" 
          className={requiredUsernameInput ? 'form-control requiredInput' : 'form-control'} 
          type="username" 
          placeholder="Username" 
          value={username} 
          onChange={
            canEditUsername ? (e) => handleUsernameOnChange(e) : () => true
          }
          label={requiredUsernameInput ? 'Username' : 'Username *'}
          ariaLabel={id
            ? `${username} is the current username. Do you wish to change it?` 
            : 'This is the required username input field. Please type in username here and submit button will no longer be disabled.'
          }
          required={!!requiredUsernameInput}
        />
      </div>
      {
        username !== 'GUEST' ? (
          <>
            <div className="form-group">
              <Input 
                htmlFor="Email" 
                className="form-control" 
                type="email" 
                placeholder="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                label="Email"
                ariaLabel={email.length ? `${email} is the current email. Do you wish to change it?` 
                  : 'This is the email input field. Please type in email here.'}
              />
            </div>
            <div className="form-group requiredFormGroupLabel">
              <div className={requiredPasswordInput ? 'requiredLabelRed input-label' : 'input-label'}>
                {id !== undefined || requiredPasswordInput ? 'Password' : 'Password *'}
              </div>
              <p className={requiredPasswordInput ? 'minorPrint' : 'hidden'}>Password must be at least 8 characters long.</p>
              <input
                className={requiredPasswordInput ? 'form-control requiredInput' : 'form-control'}  
                type="password" 
                placeholder="********" 
                value={password} 
                onChange={(e) => handlePasswordInput(e)}
                aria-label={id 
                  ? 'You may change your password in this input field'
                  : 'This is the required password input field. Choose a password that is at least 8 characters long.'}
                required
              />
            </div>
            <div className="form-group requiredFormGroupLabel">
              <div className={requiredConfirmedPasswordInput ? 'requiredLabelRed input-label' : 'input-label'}>
                {id !== undefined || requiredConfirmedPasswordInput ? 'Confirm Password' : 'Confirm Password *'}
              </div>
              <input
                className={requiredConfirmedPasswordInput ? 'form-control requiredInput requiredConfirmPasswordInput' : 'form-control'}
                type="password" 
                placeholder="********" 
                value={confirmedPassword} 
                onChange={(e) => handleConfirmedPasswordInput(e)}
                aria-label={id
                  ? 'This is the required confirm password input field. If you have changed your password then you must confirm it.'
                  : 'This is the required confirm password input field. Please type in same password here.'}
                required
              />
            </div>
            <div className="form-group">
              <div className="input-label">Admin</div>
              <input  
                className="checkbox"
                type="checkbox"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                aria-label="admin checkbox"
              />
            </div>
          </>
        ) : null
      }
        
      <div className="form-group addUser-dropdown-container requiredFormGroupLabel">
        <div className={requiredRoleInput ? 'requiredLabelRed input-label' : 'input-label'}>
          {requiredRoleInput ? 'Role' : 'Role *'}
        </div>
        <button
          type="button" 
          className={requiredRoleInput ? 'btn outlinedRequired-button dropdown-toggle' : 'btn btn-secondary dropdown-toggle'} 
          data-toggle="dropdown" 
          data-display="static" 
          aria-haspopup="true" 
          aria-expanded="false"
          aria-label={roleState.name === 'Select A Role' ? `${roleState.name} button. Selecting a role is required.` : `${roleState.name}`}
          onClick={() => { if (roleState.name === 'Select A Role') setRequiredRoleInput(true); }}
        >
          {roleState.name}
        </button>
        <div className="dropdown-menu">
          {renderRolesItems()}
        </div>
      </div>

      <div className="form-group">
        <div className="input-label">Channels</div>
        <div className="checkbox-list">
          {renderChannelItems('channels')}
        </div>
      </div>
      {
        username !== 'GUEST' ? (
          <div className="form-group">
            <div className="input-label">Access Controls</div>
            <div className="checkbox-list">
              {renderAccessControls('access-controls')}
            </div>
          </div>
        ) : null
      }

      <div className="form-group">
        <Button text="Cancel" onClick={() => toggle()} className="cancel-button" /> 
        <Button
          text={id ? 'Edit User' : 'Add User'}
          disabled={validateUser(username, password, confirmedPassword, roleState.name, id, users)}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => handleSubmit(e)}
        />
      </div>
    </main>
  ); 
};
  
export default AddEditUser;
