import { Channel } from '../../Typings/channelTypes';

export interface ChannelState {
  channels: Channel[];
  channelsVisited: boolean;
}

const initState: ChannelState = {
  channels: [],
  channelsVisited: false,
};

const channelReducer = (
  state = initState,
  action: { type: string; payload: Channel; payload2: Channel[], payload3: string; },
): ChannelState => {
  switch (action.type) {
    case 'GET_CHANNELS':
      return {
        ...state,
        channels: action.payload2,
      };
    case 'EDIT_CHANNEL_SUCCESS':
      return {
        ...state,
        channels: [...state.channels.filter((c: Channel) => c._id !== action.payload._id), action.payload] as Channel[],
      };
    case 'ADD_CHANNEL_SUCCESS':
      return {
        ...state,
        channels: [...state.channels, action.payload] as Channel[],
      };
    case 'DELETE_CHANNEL_SUCCESS':
      return {
        ...state,
        channels: state.channels.filter((c: Channel) => c._id !== action.payload3),
      };

    case 'VISIT_CHANNELS':
      return {
        ...state,
        channelsVisited: true,
      };
    default:
      return state;
  }
};

export default channelReducer;
