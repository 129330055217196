type LoginState = {
  loggedIn: boolean;
  decodedChannels: string[] | null,
  decodedPermissions: string[],
};

type Payload2 = {
  channels: string[] | null,
  permissions: string[]
}

const initialState: LoginState = {
  loggedIn: false,
  decodedChannels: null,
  decodedPermissions: [],
};

export function loginReducer(
  state = initialState,
  action: { type: string, payload: boolean, payload2: Payload2 },
): LoginState {
  switch (action.type) {
    case 'LOGIN': 
      return {
        ...state,
        loggedIn: action.payload,
      };
    case 'DECODED_TOKEN':
      return {
        ...state, decodedChannels: action.payload2.channels, decodedPermissions: action.payload2.permissions,
      };
    case 'LOGOUT':
      return {
        ...state, decodedChannels: null, decodedPermissions: [],
      };
    default:
      return state;
  }
}
