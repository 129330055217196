import React from 'react';
import DonutChart from 'react-svg-donut-chart';

type DonutData = {
  value: number;
  stroke: string;
};

type DonutProps = {
  data: DonutData[];
  heading: string;
};

// example data
// const donutData = [
//   { value: 60, stroke: '#b1d4e9' }, // should be larger value i.e: total recipients
//   { value: 12, stroke: '#0075c9' }, // should be smaller value i.e: total acknowledged
// ];

const Donut = ({ data, heading }: DonutProps): JSX.Element => {
  let percentage = null;
  if (data[1].value === 0 && data[0].value === 0) {
    percentage = 0;
    data[0].value = 1 //eslint-disable-line
  } else {
    percentage = ((data[1].value / data[0].value) * 100).toFixed(2);
  }
    
  return (
    <div className={`${heading}Donut donutContainer`}>
      <DonutChart data={data} height="250px" position="absolute" />
      <div className="donutText">
        <p aria-label={`${percentage}% has ${heading}`} id={`donut${heading}`}>
          {percentage}
          {' '}
          %
        </p>
        <p className="donutHeading">{heading}</p>
      </div>
    </div>
  );
};

export default Donut;
