import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import Status from '../../Components/Status';
import { RootState } from '../../Redux/reducers';
import { Channel } from '../../Typings/channelTypes';
import { FileType } from '../../Typings/fileTypes';
import { ToastType } from '../../Typings/toastTypes';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import SocketController from '../../Utils/SocketController';
import channelLogic from '../Channels/channelLogic';
import fileManagerLogic from './fileManagerLogic';

const fileApi = '/api/v2/core/files';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

export const handleChannels = (id: string | undefined, setSelectedChannels: (ids: string[]) => void, selectedChannels: string[]): boolean => {
  if (!id) return false;
  if (!selectedChannels.includes(id)) setSelectedChannels([...selectedChannels, id]);
  else setSelectedChannels(selectedChannels.filter((cur:string) => cur !== id));
  return true;
};

export const validateFileName = (name: string, setErrorMessage: (msg: string) => void): boolean => {
// this regex will cause a crash in some browser because they don't support look ahead
  // const regex = /^(?! )[\w\-.]+(?<! )$/; // contain only alphanumeric characters, -, _, and .

  const regex = /^[a-zA-Z0-9-_.]+$/; // same regex as above 
  if (!regex.test(name)) {
    setErrorMessage('Please enter a file name containing alphanumeric characters, -, _, and .');
    return false;
  }
  return true;
};

export const submitEditFile = async (
  e: React.MouseEvent<HTMLButtonElement>,
  id: string,
  name: string,
  channels: string[],
  offline: boolean,
  setErrorMessage: (msg: string) => void,
  toggle: () => void,
  dispatch: (action: { type: string, payload: ToastType }) => void,
  socket: SocketController,
  fromAdd?: boolean,
): Promise<boolean> => {
  if (!fromAdd) e.preventDefault();
  setErrorMessage('');
  let res: AxiosResponse<FileType>;
  const fileName = name.replace(/\s+/g, '');
  if (!validateFileName(fileName, setErrorMessage)) return false;
  const editData = { name: fileName, channels, offline };

  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };

  try {
    res = await axios.put(`${fileApi}/${id}`, editData, config);
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'File Manager',
        message: `Error editing ${fileName} file`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error editing ${fileName} file`;
  if (res.status === 200) {
    const data = { new: fromAdd || false, file: res.data };
    socket.emitUpdateFile(data);
    toggle();
    return true;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'File Manager',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};

export const submitAddFile = async (
  e: React.MouseEvent<HTMLButtonElement>,
  fileData: File,
  name: string,
  channels: string[],
  offline: boolean,
  setErrorMessage: (msg: string) => void,
  toggle: () => void,
  dispatch: (action: { type: string, payload?: ToastType | FileType[], payload2?: FileType, }) => void,
  socket: SocketController,
  setValid: (valid: boolean) => void,
): Promise<boolean> => {
  e.preventDefault();
  setValid(false);
  setErrorMessage('');
  let res: AxiosResponse;
  const data = new FormData();
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  data.append('file', fileData);

  const fileName = name.replace(/\s+/g, '');
  if (!validateFileName(fileName, setErrorMessage)) return false;

  try {
    res = await axios.post(fileApi, data, config);
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'File Manager',
        message: `Error adding ${fileName} file`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error adding ${fileName} file`;
  if (res.status === 201) {
    const putRes = await submitEditFile(e, res.data._id, name, channels, offline, setErrorMessage, toggle, dispatch, socket, true);
    if (putRes) {
      socket.emitNewFile(fileName);
      toggle();
      return true;
    }
    return false;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'File Manager',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};

const AddEditFile = ({ id, toggle }: { id?: string, toggle: () => void, }): JSX.Element => {
  const dispatch = useDispatch();
  const { channels, channelsVisited } = useSelector((state: RootState) => state.channel);
  const { files } = useSelector((state: RootState) => state.files);
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const [fileState, setFileState] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [offline, setOffline] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [requiredFile, setRequiredFile] = useState(false); // eslint-disable-line
  const [requiredFileName, setRequiredFileName] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState<any>([]); // eslint-disable-line

  useEffect(() => {
    if (!channelsVisited || !channels.length) {
      channelLogic.getChannels(dispatch);
    }
    if (!files.length) {
      fileManagerLogic.getFiles(dispatch);
    }
  }, []);

  const validateFileData = () => {
    if (id) {
      const fileNameOnlySpaces = /[a-zA-Z0-9]/.test(fileName);
      if (!fileNameOnlySpaces) return true;
      return false;
    }
    const fileNameOnlySpaces = /[a-zA-Z0-9]/.test(fileName);
    if (!fileNameOnlySpaces) return true;
    if (!fileState || !fileName) return true;
    return false;
  };

  useEffect(() => {
    if (id && files.length) {
      const selectedFile = files.find((cur: FileType) => cur._id === id);
      if (selectedFile) {
        setFileName(selectedFile.name);
        setSelectedChannels(selectedFile.channels);
        setOffline(selectedFile.offline);
      }
    }
  }, [id, files]);

  return (
    <main className="outerFormContainer">
      <form>
        { errorMessage ? <Status message={errorMessage} type="error" /> : null }
        <div className="form-group">
          <Input
            htmlFor="File Name:"
            label={requiredFileName ? 'File Name:' : 'File Name: *'}
            type="text"
            className={requiredFileName ? 'form-control requiredInput' : 'form-control'}
            value={fileName}
            onChange={
              (e) => {
                setFileName(e.target.value); 
                if (e.target.value === '') setRequiredFileName(true); 
                if (e.target.value !== '') setRequiredFileName(false); 
              }}
            ariaLabel={id ? `${fileName} is the current name for this file. Do you wish to change it?` 
              : 'This is the required file name input field. Please type in name of file here and submit button will no longer be disabled.'}
            required={!!requiredFileName}
          />
        </div>
        {
          !id ? (
            <div className="form-group fileFormGroup">
              <FilePond
                files={filesUploaded} // eslint-disable-line
                onupdatefiles={setFilesUploaded}
                instantUpload={false}
                onaddfile={(error, fileItem) => {
                  setFileName(fileItem.file.name); 
                  setErrorMessage(''); 
                  const fileSize = fileItem.file.size / 1024 / 1024;
                  if (fileSize > 15) {
                    setErrorMessage('Please choose a file that does not exceed 15MB');
                  }
                  setFileState(fileItem.file); 
                } 
                }
                name="files"
                dropOnElement
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/svg']}
              />
            </div>
          ) : null
      }
        <div className="form-group">
          <p className="input-label smallBottomMargin">Channels: </p>
          <p className="minorPrint">Add channels associated with the file</p>
          <div className="checkbox-list">
            {
              channels && channels.map((cur: Channel) => (
                <div key={cur._id} className="form-check">
                  <input
                    key={cur._id}
                    name="channel"
                    type="checkbox"
                    value={cur._id}
                    aria-label={selectedChannels.includes(cur._id)
                      ? `Do you want to uncheck ${cur.name} channel for this file?`
                      : `Do you want to check ${cur.name} channel for this file?`}
                    checked={selectedChannels && cur._id ? selectedChannels.includes(cur._id) : false}
                    onChange={() => handleChannels(cur._id, setSelectedChannels, selectedChannels)}
                  />
                  <label htmlFor="channel" className="form-check-label form-checkbox">{cur.name}</label>
                </div>
              ))
              }
          </div>
        </div>
        <div className="form-group">
          <p className="input-label smallBottomMargin">File Type: </p>
          <p className="minorPrint">Offline allows access to files on the mobile app without an internet connection</p>
          <div className="form-check form-check-inline">
            <label className="form-check-label" htmlFor="offlineRadio">
              <input
                className="form-check-input"
                type="radio"
                name="fileType"
                id="offlineRadio"
                value="offline"
                aria-label={offline
                  ? 'Do you want to change to \'online\' for this file? If you choose to change to online then this file will not be accessable offline on the mobile app.' // eslint-disable-line
                  : 'Do you want to change to \'offline\' for this file? If you choose to change to offline then this file will be accessable offline on the mobile app.'} // eslint-disable-line
                checked={offline}
                onChange={() => setOffline(true)}
              />
              Offline
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label" htmlFor="onlineRadio">
              <input
                className="form-check-input"
                type="radio"
                name="fileType"
                id="onlineRadio"
                value="online"
                checked={!offline}
                onChange={() => setOffline(false)}
              />
              Online
            </label>
          </div>
        </div>
        <div className="form-group">
          <Button text="Cancel" onClick={() => toggle()} className="cancel-button" />
          <Button
            disabled={validateFileData()}
            text="Submit"
            onClick={
              !id ? (e) => e && fileState && submitAddFile(
                e, fileState, fileName, selectedChannels, offline, setErrorMessage, toggle, dispatch, socket, setRequiredFile,
              )
                : (e) => e && submitEditFile(e, id, fileName, selectedChannels, offline, setErrorMessage, toggle, dispatch, socket)
            }
            type="submit"
          />
        </div>
      </form>
    </main>
  );
};

export default AddEditFile;
