import React, { useState } from 'react';
import moment from 'moment';

import { useDashboardContext } from '../Context/dashboardContext';
import { Alert } from '../Typings/alertTypes';
import Button from './Button';

type AlertCardProps = {
  alert: Alert;
};

const AlertCard = ({ alert }: AlertCardProps): JSX.Element => {
  const [trunc, setTrunc] = useState(true);
  const { updateAlertDetailsDisplay, renderChannels } = useDashboardContext();

  return (
    <div
      key={`${alert._id}alertCard`}
      id={alert._id}
      className={trunc ? 'card alertCard' : 'card alertCard elongated-text'}
      onKeyPress={() => setTrunc(!trunc)}
      onClick={() => setTrunc(!trunc)}
      role="button"
      aria-pressed={false}
      tabIndex={0} //eslint-disable-line
    >
      <div className="alertCardHeader">
        <div className="pillContainer">{renderChannels(alert)}</div>
        <p className="timestamp">{moment(alert.sent).format('h:mm a')}</p>
      </div>
      <div className="alertCardHeader">
        <p className={trunc ? 'truncated-text' : 'alertContent'}>{alert.content}</p>
        <Button
          className="primary-button detailsButton"
          text="Details" 
          onClick={() => updateAlertDetailsDisplay(alert._id)}
        />
      </div>
    </div>
  );
};

export default AlertCard;
