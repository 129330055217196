import io from 'socket.io-client';
import dashboardLogic from '../Containers/Dashboard/dashboardLogic';
import { deauth, refreshToken } from '../Utils/auth';
import moment from 'moment';

class SocketController {
    constructor(dispatch) {
        this.client = io(`${process.env.REACT_APP_SERVER_URL}`, {
            transports: ['websocket', 'polling'],
            path: `${process.env.REACT_APP_SOCKET_PATH}`,
            autoConnect: true,
        });
        this.setupSocket();
        this.dispatch = dispatch;
        this.dashboardLogic = dashboardLogic;
    }

    setupSocket() {
        this.client.on('connect', () => {
            console.log('connected');

            /***********************************************
             * Backend Valcom message test implementation
             ***********************************************/
            //Polls saved messages on initial connection
            // TODO you'll want to emit this only after an UI user has logged in
            this.client.emit('pollValcomMessages');
            //Listener for live incoming Valcom message while connected
            this.client.on('newValcomMessage', () => {
               console.log('A new Valcom message is acknowledged, polling messages...');
               this.client.emit('pollValcomMessages');
            });
            //Listener for returned Valcom messages
            this.client.on('returnValcomMessages', (data) => {
               console.log('Valcom messages returned:');
               console.log(data);
               // loop through messages, and emit delete for each one
               // TODO this emit will be used when you have the user dismiss a message
               data.forEach((msg) => {
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                        messageId: msg._id,
                        heading: 'Valcom',
                        message: msg.message,
                        autoDismiss: false,
                        toastType: 'info',
                        timeStamp: moment(msg.timeReceived),
                    }
                });
               });
            });
            
            this.client.on('deleteValcomMessage', (data) => {
                this.dispatch({
                    type: 'DELETE_TOAST',
                    payload: data
                });
            });

            this.client.on('activeUsers', (data) => {
                this.dispatch({ type:'ACTIVE_USERS', payload: data.length });
            });

            this.client.on('updatedDevice', (data) => {
                this.dispatch({ type: 'DEVICES_UPDATED', updatedDevice: data });
            });

            this.client.on('newUserCreated', (data) => {
                this.dispatch({ type: 'ADD_USER_SUCCESS', payload: data });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                    toastType: 'success',
                    heading: 'Users',
                    message: `${data.username} user added`,
                    autoDismiss: true,
                    },
                });
            });

            this.client.on('updatedUser', (data) => {
                this.dispatch({ type: 'EDIT_USER_SUCCESS', payload2: data });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                        toastType: 'success',
                        heading: 'Users',
                        message: `${data.username} user edited`,
                        autoDismiss: true,
                    },
                });
                const currentUser = sessionStorage.getItem('authorizedUser');
                if (currentUser === data._id) {
                    if (!data.admin) {
                        window.location.assign('/login');
                        return deauth(this.dispatch);
                    }
                    refreshToken();
                }
            });

            this.client.on('deletedUser', (data) => {
                this.dispatch({ type: 'DELETE_USER_SUCCESS', payload: data });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                        toastType: 'success',
                        heading: 'Users',
                        message: 'A user has been deleted',
                        autoDismiss: true,
                    },
                });
            });


            this.client.on('newToken', (data) => {
                this.dispatch({ type: 'ADD_TOKEN', payload: data });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                      toastType: 'success',
                      heading: 'Token',
                      message: `${data.name} token added`,
                      autoDismiss: true,
                    },
                  });
            });

            this.client.on('refreshedToken', (data) => {
                this.dispatch({ type: 'REFRESH_TOKEN', payload2: data });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                    toastType: 'success',
                    heading: 'Tokens',
                    message: `${data.name} token refreshed`,
                    autoDismiss: true,
                    },
                });
            });

            this.client.on('deletedToken', (id) => {
                this.dispatch({ type: `DELETE_TOKENS`, payload: id });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                    toastType: 'success',
                    heading: `Token`,
                    message: `A token was deleted`,
                    autoDismiss: true,
                    },
                });
            });

            this.client.on('deletedRole', (id) => {
                this.dispatch({ type: `DELETE_ROLES`, payload: id });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                    toastType: 'success',
                    heading: `Roles`,
                    message: `A role was deleted`,
                    autoDismiss: true,
                    },
                });
            });

            this.client.on('updatedRole', (data) => {
                this.dispatch({ type: 'EDIT_ROLE', payload2: data });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                        toastType: 'success',
                        heading: 'Role',
                        message: `${data.name} role edited`,
                        autoDismiss: true,
                    },
                });
                const currentRole = sessionStorage.getItem('userRole');
                if (currentRole === data._id) refreshToken();
            });

            this.client.on('newRole', (data) => {
                this.dispatch({
                    type: 'ADD_ROLE',
                    payload2: data,
                });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                        toastType: 'success',
                        heading: 'Role',
                        message: `${data.name} role added`,
                        autoDismiss: true,
                    },
                });
            });

            this.client.on('deviceDeleted', (data) => {
                this.dispatch({ type: 'DEVICES_DELETED', data });
            });

            this.client.on('alertFeedback', (data) => {
                this.dispatch({ type: 'ALERT_COUNTS', data });
            });

            this.client.on('socketErrors', (data) => {
                this.dispatch({ type: 'ALERT_ERRORS', data });
            });

            this.client.on('respondedAlert', (data) => {
                this.dispatch({ type: 'ALERT_RESPONSES', data });
            });

            this.client.on('newAlertCreated', (data) => {
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                        toastType: 'success',
                        heading: 'Sender',
                        message: 'New alert received',
                        autoDismiss: true,
                    },
                });
                this.dashboardLogic.fetchAlerts(this.dispatch, 1)
            });

            this.client.on('newChannel', (data) => {
                this.dispatch({ type: 'ADD_CHANNEL_SUCCESS', payload: data });
                this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                        toastType: 'success',
                        heading: 'Channels',
                        message: `${data.name} channel added`,
                        autoDismiss: true,
                    },
                });
            });

            this.client.on('updatedChannel', (data) => {
                this.dispatch({ type: 'EDIT_CHANNEL_SUCCESS', payload: data });
                this.dispatch({
                type: 'ADD_TOAST',
                    payload: {
                        toastType: 'success',
                        heading: 'Channels',
                        message: `${data.name} channel edited`,
                        autoDismiss: true,
                    },
                });
            });

            this.client.on('deletedChannel', (data) => {
                this.dispatch({
                    type: 'DELETE_CHANNEL_SUCCESS',
                    payload3: `${data.id}`,
                  });

                  this.dispatch({
                    type: 'ADD_TOAST',
                    payload: {
                      toastType: 'success',
                      heading: 'Channels',
                      message: `${data.name} channel deleted`,
                      autoDismiss: true,
                    },
                  });
            });
        });

        this.client.on('newFile', (data) => {
            this.dispatch({
                type: 'ADD_TOAST',
                payload: {
                  toastType: 'success',
                  heading: 'File Manager',
                  message: `${data} file added`,
                  autoDismiss: true,
                },
              });
        });

        this.client.on('updatedFile', (data) => {
            if (data.new) {
                this.dispatch({
                    type: 'ADD_FILE',
                    payload2: data.file,
                });
            } else {
                this.dispatch({
                    type: 'EDIT_FILE',
                    payload2: data.file,
                });

                this.dispatch({
                    type: 'ADD_TOAST',
                        payload: {
                            toastType: 'success',
                            heading: 'File Manager',
                            message: `${data.file.name} file edited`,
                            autoDismiss: true,
                        },
                    });
            }
        });

        this.client.on('deletedFile', (data) => {
            this.dispatch({ type: 'DELETE_FILE', payload: data.id });
            this.dispatch({
                type: 'ADD_TOAST',
                payload: {
                toastType: 'success',
                heading: 'File Manager',
                message: `${data.msg} file deleted`,
                autoDismiss: true,
                },
            });
        });

        this.client.on('guestUpdate', (data) => {
            this.dispatch({ type: 'EDIT_USER_SUCCESS', payload2: data });
            this.dispatch({
                type: 'ADD_TOAST',
                payload: {
                    toastType: 'success',
                    heading: 'Users',
                    message: `${data.username} user edited`,
                    autoDismiss: true,
                },
            });
        });

        this.client.on('disconnect', () => {
            console.log('socket.io disconnected');
            this.client.removeAllListeners();
            setTimeout(() => {
                this.client = io(`${process.env.REACT_APP_SERVER_URL}`, {
                    transports: ['websocket', 'polling'],
                    path: `${process.env.REACT_APP_SOCKET_PATH}`,
                    autoConnect: true,
                });
                this.setupSocket();
            }, 500);
        });
        this.client.on('errorHandler', (data) => console.log(data.message));
    }

    emitActiveUsers() {
        this.client.emit('getActiveUsers');
    }

    emitNewFile(data) {
        this.client.emit('fileCreated', data);
        this.dispatch({
            type: 'ADD_TOAST',
                payload: {
                    toastType: 'success',
                    heading: 'File Manager',
                    message: `${data} file added`,
                    autoDismiss: true,
                },
            });
    }

    emitUpdateFile(data) {
        this.client.emit('fileUpdated', data);
        if (data.new) {
            this.dispatch({
                type: 'ADD_FILE',
                payload2: data.file,
            });
        } else {
            this.dispatch({
                type: 'EDIT_FILE',
                payload2: data.file,
            });
            this.dispatch({
                type: 'ADD_TOAST',
                    payload: {
                        toastType: 'success',
                        heading: 'File Manager',
                        message: `${data.file.name} file edited`,
                        autoDismiss: true,
                    },
                });
        }
    }

    emitDeleteFile(data) {
        this.client.emit('fileDeleted', data);
        this.dispatch({ type: 'DELETE_FILE', payload: data.id });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
              toastType: 'success',
              heading: 'File Manager',
              message: `${data.msg}`,
              autoDismiss: true,
            },
          });
    }

    emitDeleteValcom(messageID) {
        this.client.emit('removeValcomMessage', { id: messageID });
    }

    emitCurrent() {
        this.client.emit('current', { deviceId: 'UI User' });
    }

    emitNewUser(data) {
        this.client.emit('createdUser', data);
        this.dispatch({ type: 'ADD_USER_SUCCESS', payload: data });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
                toastType: 'success',
                heading: 'Users',
                message: `${data.username} user added`,
                autoDismiss: true,
            },
        });
    }

    emitUpdatedUser(data) {
        this.client.emit('userUpdate', data);
        this.dispatch({ type: 'EDIT_USER_SUCCESS', payload2: data });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
                toastType: 'success',
                heading: 'Users',
                message: `${data.username} user edited`,
                autoDismiss: true,
            },
        });
        const currentUser = sessionStorage.getItem('authorizedUser');
        if (currentUser === data._id ) {
            if (!data.admin) {
                window.location.assign('/login');
                return deauth(this.dispatch);
            }
            refreshToken();
        }
    }

    emitUserDeletion(id, name) {
        this.client.emit('userDeletion', id);
        this.dispatch({ type: 'DELETE_USER_SUCCESS', payload: id });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
                toastType: 'success',
                heading: 'Users',
                message: `${name} user deleted`,
                autoDismiss: true,
            },
        });
    }

    emitNewChannel(data) {
        this.client.emit('channelCreated', data);
        this.dispatch({ type: 'ADD_CHANNEL_SUCCESS', payload: data });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
                toastType: 'success',
                heading: 'Channels',
                message: `${data.name} channel added`,
                autoDismiss: true,
            },
        });
    }

    emitUpdateChannel(data) {
        this.client.emit('channelUpdate', data);
        this.dispatch({ type: 'EDIT_CHANNEL_SUCCESS', payload: data });
        this.dispatch({
        type: 'ADD_TOAST',
        payload: {
            toastType: 'success',
            heading: 'Channels',
            message: `${data.name} channel edited`,
            autoDismiss: true,
        },
        });
    }

    emitDeleteChannel(id, name, data) {
        this.client.emit('channelDeletion', data);
        this.dispatch({
            type: 'DELETE_CHANNEL_SUCCESS',
            payload3: `${id}`,
          });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
              toastType: 'success',
              heading: 'Channels',
              message: `${name} channel deleted`,
              autoDismiss: true,
            },
          });
    }

    emitNewAlert(data) {
        this.client.emit('alertCreated', data);
    }

    emitNewToken(data) {
        this.client.emit('tokenCreated', data);
        this.dispatch({ type: 'ADD_TOKEN', payload: data });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
              toastType: 'success',
              heading: 'Token',
              message: `${data.name} token added`,
              autoDismiss: true,
            },
          });
    }

    emitRefreshToken(data) {
        this.client.emit('tokenRefreshed', data);
        this.dispatch({ type: 'REFRESH_TOKEN', payload2: data });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
              toastType: 'success',
              heading: 'Tokens',
              message: `${data.name} token refreshed`,
              autoDismiss: true,
            },
          });
    }

    emitDeleteToken(id, name) {
        this.client.emit('tokenDeleted', id);
        this.dispatch({ type: `DELETE_TOKENS`, payload: id });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
            toastType: 'success',
            heading: `Tokens`,
            message: `${name} token deleted`,
            autoDismiss: true,
            },
        });
    }

    emitDeleteRole(id, name) {
        this.client.emit('roleDeleted', id);
        this.dispatch({ type: `DELETE_ROLES`, payload: id});
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
            toastType: 'success',
            heading: `Roles`,
            message: `${name} role deleted`,
            autoDismiss: true,
            },
        });
    }

    emitUpdateRole(data) {
        this.client.emit('roleUpdated', data);
        this.dispatch({ type: 'EDIT_ROLE', payload2: data });
        this.dispatch({
          type: 'ADD_TOAST',
          payload: {
            toastType: 'success',
            heading: 'Role',
            message: `${data.name} role edited`,
            autoDismiss: true,
          },
        });
        const uRole = sessionStorage.getItem('userRole');
        if (uRole === data._id) refreshToken();
    }

    emitNewRole(data) {
        this.client.emit('roleCreated', data);
        this.dispatch({
                type: 'ADD_ROLE',
                payload2: data,
            });
        this.dispatch({
        type: 'ADD_TOAST',
        payload: {
            toastType: 'success',
            heading: 'Role',
            message: `${data.name} role added`,
            autoDismiss: true,
        },
        });
    }

    emitUpdatedGuest(data) {
        this.client.emit('updatedGuestUser', data);
        this.dispatch({ type: 'EDIT_USER_SUCCESS', payload2: data });
        this.dispatch({
            type: 'ADD_TOAST',
            payload: {
                toastType: 'success',
                heading: 'Users',
                message: `${data.username} user edited`,
                autoDismiss: true,
            },
        });
    }
}

export default SocketController;

