import React from 'react';

export interface InputProps {
  placeholder?: string;
  htmlFor: string;
  label?: string;
  type?: string;
  value: string | number | readonly string[] | undefined;
  disabled?: boolean;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => boolean | void;
  ariaLabel?: string;
  required?: boolean;
}

const InputField = ({
  placeholder,
  htmlFor,
  label,
  type,
  value,
  disabled,
  className,
  onChange,
  ariaLabel,
  required,
}: InputProps): JSX.Element => (
  <div className="input-field-container">
    <label htmlFor={htmlFor} className={required ? 'input-label requiredLabelRed' : 'input-label'}>
      {label}
    </label>
    {type === 'text' || (type === undefined && placeholder !== undefined) ? (
      <input
        placeholder={placeholder !== undefined ? placeholder : ''}
        type="text"
        value={value}
        className={
            className !== undefined && className !== ''
              ? className
              : 'input-field'
          }
        disabled={disabled !== undefined ? disabled : false}
        onChange={onChange}
        aria-label={ariaLabel}
        required={required}
      />
    ) : (
      <input
        placeholder={placeholder !== undefined ? placeholder : ''}
        type={type !== undefined ? type : 'text'}
        value={value}
        className={className !== undefined ? className : ''}
        disabled={disabled !== undefined ? disabled : false}
        onChange={onChange}
        aria-label={ariaLabel}
        required={required}
      />
    )}
  </div>
);

export default InputField;
