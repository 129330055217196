import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../Redux/reducers';
import { ToastComponentType } from '../Typings/toastTypes';

const Toast = ({
  messageId, heading, message, autoDismiss, toastType, timeStamp, handleDismiss,
}: ToastComponentType): JSX.Element => {
  let confirmedType = toastType;
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  // const [showHideToast, setShowHideToast] = useState(true);
  if (confirmedType !== 'warning' && confirmedType !== 'success' && confirmedType !== 'info') confirmedType = 'danger';
  if (autoDismiss) {
    setTimeout(() => handleDismiss(), 10000);
  }
  return (
    <div
      className="toast"
      style={{ opacity: 1 }}
      data-bs-autohide={autoDismiss || false}
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className={`toast-header bg-${confirmedType}`}>
        <strong role="heading" aria-level={1} className="mr-auto" style={{ color: 'white' }}>{heading}</strong>
        <small>{timeStamp && timeStamp.fromNow()}</small>
        <button
          type="button"
          className="ml-2 mb-1 close"
          onClick={toastType === 'info' ? () => {
            socket.emitDeleteValcom(messageId); handleDismiss()// eslint-disable-line
          } : () => handleDismiss()}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body" style={{ backgroundColor: 'white' }}>
        {message}
      </div>
    </div>
  );
};

export default Toast;
