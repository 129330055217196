import SocketController from '../../Utils/SocketController';

interface SocketState {
  socket: SocketController | any; //eslint-disable-line
}

const initState = {
  socket: {},
};

export function socketReducer(
  state = initState,
  action: { type: string; payload: SocketController},
): SocketState {
  switch (action.type) {
    case 'NEW_SOCKET': 
      return {
        ...state,
        socket: action.payload,
      };
      
    default:
      return state;
  }
}
