import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChromePicker } from 'react-color';
import Button from '../../Components/Button';
import channelLogic from './channelLogic';
import { RootState } from '../../Redux/reducers';

export const validateChannel = (channelName: string):boolean => {
  const ifNotOnlySpace = /[a-zA-Z0-9]/.test(channelName); 
  if (!ifNotOnlySpace) return true;  
  if (channelName === undefined || channelName === null || channelName === '') return true;
  return false;
};

const AddEditChannelPage = ({ id, toggle }: { toggle: () => void, id?: string}): JSX.Element => {
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const [channelName, setChannelName] = useState('');
  const [channelStyle, setChannelStyle] = useState('#FFFFFF');
  const [channelColor, setChannelColor] = useState('#000000');
  const [channelSticky, setChannelSticky] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const channel = {
    name: channelName, style: channelStyle, color: channelColor, sticky: channelSticky,
  };
  const [requiredChannelName, setRequiredChannelName] = useState(false);

  useEffect(() => {
    if (id !== undefined) {
      channelLogic.getChannelById(
        id,
        setChannelName,
        setChannelStyle,
        setChannelColor,
        setChannelSticky,
        setErrorMessage,
      );
    }
  }, [id]);

  const handleChannelNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChannelName(e.target.value);
    if (e.target.value === '') setRequiredChannelName(true); 
    if (e.target.value !== '') setRequiredChannelName(false); 
  };

  return (
    <main>
      {errorMessage.length ? <div role="alert" aria-live="assertive" className="alert alert-danger">{errorMessage}</div> : null}
      <div className="outerFormContainer">
        <form className="form-center">
          <div className="form-group requiredFormGroupLabel">
            <div className={requiredChannelName ? 'requiredLabelRed input-label' : 'input-label'}>
              {requiredChannelName ? 'Channel Name' : 'Channel Name *'}
            </div>
            <p className="minorPrint">Symbols (i.e @, #, $...) are not allowed.</p>
            <input 
              id="channel name input"
              type="text"
              value={channelName}
              className={requiredChannelName ? 'form-control requiredInput' : 'form-control'}
              onChange={(e) => handleChannelNameOnChange(e)}
              aria-label={id ? `${channelName} is the current name for this channel. Do you wish to change it?` 
                : 'This is the required channel name input field. Please type in name of channel here and submit button will no longer be disabled.'}
              aria-required="true"
              required
            />
          </div>
          <div className="form-group">
            <div className="input-label">Text Color</div>
            <ChromePicker
              className="chromePickerCustomization" 
              color={channelColor} 
              onChange={(color) => setChannelColor(color.hex)}
            />
          </div>
          <div className="form-group">
            <div className="input-label">Background Color</div>
            <ChromePicker
              className="chromePickerCustomization" 
              color={channelStyle} 
              onChange={(color) => setChannelStyle(color.hex)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="input-checkbox" className="input-label">
              Sticky
              <input
                id="input-checkbox"
                type="checkbox"
                name="input-checkbox"
                className="input-checkbox"
                checked={channelSticky}
                onChange={() => setChannelSticky(!channelSticky)}
                aria-label="channel sticky checkbox"
              />
            </label>
          </div>
          <div className="form-group">
            <Button ariaLabel="cancel button" text="Cancel" onClick={() => toggle()} type="button" className="cancel-button ml-0" />
            <Button
              text={id ? 'Edit Channel' : 'Add Channel'}
              onClick={id ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => channelLogic.handleSubmit(
                e, channel, setErrorMessage, dispatch, socket, false, toggle, id,
              )
                : (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => channelLogic.handleSubmit(
                  e, channel, setErrorMessage, dispatch, socket, true, toggle,
                )
            }
              type="submit"
              disabled={validateChannel(channelName)}
              ariaLabel="submit button"
              className="primary-button"
            />
          </div>
        </form>
      </div>
    </main>
  );
};

export default AddEditChannelPage;

