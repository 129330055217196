import axios, { AxiosResponse } from 'axios';
import { Role, AccessControls } from '../../Typings/adminTypes';
import { Channel } from '../../Typings/channelTypes';
import { User, UserChannel } from '../../Typings/userTypes';
import { ToastType } from '../../Typings/toastTypes';
import SocketController from '../../Utils/SocketController';

export type userChannel = {
  active: boolean;
  _id: string;
  channel?: string;
}

const mapChannelNames = (userChannels: userChannel[] | undefined, channels: Channel[]): UserChannel[] => {
  const userChannelIds = userChannels && userChannels.map((channelObj) => {// eslint-disable-line
    if (channelObj && channelObj._id) return channelObj.channel;
  });
  const channelInfo: UserChannel[] = [];

  if (channels && userChannels) {
    const filtered = channels.filter((curChannel: Channel) => userChannelIds && userChannelIds.includes(curChannel._id));
    filtered.forEach((channel: Channel) => {
      channelInfo.push({ _id: channel._id, active: true, channel });
    });
  }
  return channelInfo;
};

const mapAccessControls = (userAccessControls: string[], accessControls: AccessControls[]): string[] => {
  const accessControlInfo: string[] = [];

  if (accessControls && userAccessControls) {
    const filtered = accessControls.filter((curAccessControl: AccessControls) => userAccessControls.includes(curAccessControl._id));
    filtered.forEach((accessControl: AccessControls) => {
      accessControlInfo.push(accessControl.name);
    });
  }
  return accessControlInfo;
};

const isAdmin = (admin: boolean): string => {
  const adminString = admin ? 'Admin' : '';
  return adminString;
};

const checkAdminPassword = async (userID: string, setShowPassModal: React.Dispatch<React.SetStateAction<boolean>>): Promise<boolean> => {
  const loggedInUser:any = await getUserById(userID); //eslint-disable-line
  if (loggedInUser.user.username === 'admin' && loggedInUser.user.passwordReset === false) {
    setShowPassModal(true);
    return true;
  }
  return false;
};

const mapRoles = (userRole: string, roles: Role[]): string => {
  let filteredRole: Role | undefined;
  if (roles && userRole) {
    filteredRole = roles.find((r: Role) => r._id === userRole);
    if (filteredRole) return `Role: ${filteredRole.name}`;
    return '';
  }
  return '';
};

const getUsers = async (dispatch: (action: { type: string, payload?: User[] }) => void): Promise<boolean> => {
  let res: AxiosResponse<User[]>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    res = await axios.get('/api/v2/user/users?username[$regex]=^((?!GUEST-).)*$', config);
  } catch (err) {
    return false;
  }
  if (res.status === 200) {
    dispatch({ type: 'GET_USERS', payload: res.data });
    dispatch({ type: 'VISIT_USERS' });
    return true;
  }
  return false;
};

const getUserById = async (id: string): Promise<{ user: User } | boolean> => {
  let res: AxiosResponse<{user: User}>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    res = await axios.get(`api/v2/user/users/${id}`, config);
  } catch (err) {
    return false;
  }
  return res.data;
};

const deleteUser = async (
  { delId, delName } : {delId: string, delName: string},
  dispatch: (data: { type: string, payload: string | ToastType }) => void,
  socket: SocketController,
): Promise<boolean> => {
  let res: AxiosResponse<User[]>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    res = await axios.delete(`/api/v2/user/users/${delId}`, config);
  } catch (error: unknown) {
    dispatch({
      type: 'ADD_TOAST', 
      payload: { 
        toastType: 'danger', 
        heading: 'Users', 
        message: `Error deleting ${delName} user`,
        autoDismiss: true,        
      },
    });
    return false;
  }
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error deleting ${delName} user`;
  if (res.status === 200) {
    socket.emitUserDeletion(delId, delName);
    return true;
  }
  dispatch({
    type: 'ADD_TOAST', 
    payload: { 
      toastType: 'danger', 
      heading: 'Users',
      message: `${errMessage}`,
      autoDismiss: true,        
    },
  });
  return false;
};

export default {
  getUsers,
  deleteUser,
  mapChannelNames,
  mapAccessControls,
  mapRoles,
  getUserById,
  isAdmin,
  checkAdminPassword,
};
