/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unsafe-assignment */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Sidebar, NavigationBar } from './Components/Navigation';
import { RootState } from './Redux/reducers';
import ToastSection from './Components/ToastSection';
import { ToastType } from './Typings/toastTypes';
import Copyright from './Components/Copyright';

const AppFrame = (props: any): JSX.Element => {
  const { toasts } = useSelector((state: RootState) => state.toasts);
  const { copyrights } = useSelector((state: RootState) => state.copyrights);
  const { children, location } = props;

  const renderToastSection = (toastArray: ToastType[]) => {
    if (toasts.length) return <ToastSection toasts={toastArray} />;
    return null;
  };

  return (
    <div className={location.pathname !== '/admin' ? 'wrapper-container' : 'wrapper-container-admin'}>
      <div className="wrapper">
        { location.pathname !== '/' && location.pathname !== '/login' ? <div className="side-panel"><Sidebar /></div> : null }
        { location.pathname === '/' || location.pathname === '/login' ? <div className="login-panel">{children}</div> : null }
        <div className="app-panel">
          { location.pathname !== '/' && location.pathname !== '/login' ? <NavigationBar /> : null }
          { location.pathname !== '/' && location.pathname !== '/login' ? renderToastSection(toasts) : null }
          { location.pathname !== '/' && location.pathname !== '/login' ? children : null }
        </div>
      </div>
      <Copyright 
        model={copyrights.model} 
        version={copyrights.version} 
        copyright={copyrights.copyright}
      />
    </div>
  );
};

export default withRouter(AppFrame);
