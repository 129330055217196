import axios, { AxiosResponse } from 'axios';
import { AccessControls } from '../../Typings/adminTypes';
import { EditData, User } from '../../Typings/userTypes';
import { ToastType } from '../../Typings/toastTypes';
import { Channel } from '../../Typings/channelTypes';
import SocketController from '../../Utils/SocketController';
import userLogic, { userChannel } from './usersLogic';

const validateData = (
  username: string, email: string, password: string, setErrorState: (msg: string) => void, roleState: { name: string }, edit: boolean,
): boolean => {
  const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!username.length) {
    setErrorState('Please enter a username');
    return false;
  }
  if (email.length) {
    if (!regEx.test(email)) {
      setErrorState('Please enter a valid email');
      return false;
    }
  }
  if (!edit || password.length) {
    if (password.length < 8) {
      setErrorState('Please enter a password of at least 8 characters.');
      return false;
    }
  }

  if (roleState.name === 'Select A Role' || !roleState || !roleState.name) {
    setErrorState('Please select a role');
    return false;
  }
  return true;
};

const submitAddUser = async (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  setErrorState: (msg: string) => void,
  username: string,
  email: string,
  password: string,
  roleState: { id: string, name: string },
  channelState: string[],
  channels: Channel[],
  accessControlState: string[],
  accessControls: AccessControls[],
  isChecked: boolean,
  dispatch: (action: { type: string, payload: User[] | ToastType | User }) => void,
  toggle: () => void,
  socket: SocketController,
): Promise<boolean> => {
  if (e !== undefined) e.preventDefault();
  setErrorState('');
  const isValid = validateData(username, email, password, setErrorState, roleState, false);
  if (!isValid) {
    return false;
  }
  const newChannels = channelState.map((c: string) => ({ active: true, channel: c }));
  const addUserData = {
    username,
    email,
    password,
    admin: isChecked,
    role: roleState.id,
    channels: newChannels,
    accessControls: accessControlState,
  };
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    const res: AxiosResponse<{ channels: userChannel[], _id: string, sticky: boolean }> = await axios.post('/api/v2/user/users', addUserData, config);
    const errMessage = res.status === 401 ? 'Unauthorized' : `Error adding ${username} user`;
    if (res.status === 201) {
      // dispatch calls occur in the socket controller
      const data = {
        ...res.data,
        channels: userLogic.mapChannelNames(res.data.channels, channels),
      };
      socket.emitNewUser(data);
      toggle();
      return true;
    }
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Users',
        message: `${errMessage}`,
        autoDismiss: true,
      },
    });
    return false;
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Users',
        message: `Error adding ${username} user`,
        autoDismiss: true,
      },
    });
    return false;
  }
};

const arrayofChannels = (
  channel: Channel,
  channelState: string[],
  setChannelState: (chanIds: string[]) => void,
): boolean => {
  if (channelState.includes(channel._id)) {
    setChannelState(channelState.filter((chan: string) => chan !== channel._id));
    return true;
  }
  setChannelState([...channelState, channel._id]);
  return false;
};

const arrayofAccessControls = (
  i: { _id: string },
  accessControlState: string[],
  setAccessControlState: (mes: string[]) => void,
): boolean => {
  if (accessControlState.includes(i._id)) {
    setAccessControlState(accessControlState.filter((acc: string) => acc !== i._id));
    return true;
  }
  setAccessControlState([...accessControlState, i._id]);
  return false;
};

const isItemChecked = (
  i: Channel | AccessControls,
  itemType: string,
  channelState: string[],
  accessControlState: string[],
): boolean => {
  if (itemType === 'channels') return channelState.length ? channelState.includes(i._id) : false;
  return accessControlState.length ? accessControlState.includes(i._id) : false;
};

const submitEditUser = async (
  e: React.MouseEvent<HTMLButtonElement> | undefined,
  id: string,
  channelState: string[],
  channels: Channel[],
  isChecked: boolean,
  accessControlState: string[],
  roleState: { id: string, name: string },
  username: string,
  password: string,
  email: string,
  toggle: () => void,
  dispatch: (action: { type: string, payload: ToastType | User[] }) => void,
  setErrorState: (msg: string) => void,
  socket: SocketController,
): Promise<boolean> => {
  if (e !== undefined) e.preventDefault();
  const userChannels = channelState.map((c: string) => ({ active: true, channel: c }));

  const isValid = validateData(username, email, password, setErrorState, roleState, true);
  if (!isValid) {
    return false;
  }

  const data: EditData = {
    admin: isChecked, 
    username, 
    role: roleState.id,
    accessControls: accessControlState,
  };
  if (userChannels) data.channels = userChannels;
  if (password.length) data.password = password;
  if (email.length) data.email = email;

  let res: AxiosResponse<{ channels: userChannel[], _id: string, sticky: boolean}>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    res = await axios.put(`/api/v2/user/users/${id}`, data, config);
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Users',
        message: `Error editing ${data.username} user`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error editing ${data.username} user`;
  if (res.status === 200) {
    const userData = {
      ...res.data,
      channels: userLogic.mapChannelNames(res.data.channels, channels),
    };
    if (data.username !== 'GUEST') socket.emitUpdatedUser(userData);
    else {
      const guestData = await userLogic.getUserById(id);
      if (typeof (guestData) !== 'boolean') {
        socket.emitUpdatedGuest(guestData.user);
      }
    }
    toggle();
    return true;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'Users',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};

export default {
  validateData, submitAddUser, arrayofChannels, arrayofAccessControls, isItemChecked, submitEditUser,
};
