import { ToastType } from '../../Typings/toastTypes';

export interface ToastState {
  toasts: ToastType[];
}

const initState: ToastState = {
  toasts: [],
};

const toastReducer = (
  state = initState,
  action: { type: string; payload: ToastType },
): ToastState => {
  switch (action.type) {
    case 'ADD_TOAST': 
      return {
        ...state,
        toasts: [action.payload, ...state.toasts] as ToastType[],
      };
    case 'DELETE_TOAST':
      return {
        ...state,
        toasts: state.toasts.filter((t: ToastType) => t !== action.payload),
      };
    default:
      return state;
  }
};

export default toastReducer;
