import { Styles } from '../../Typings/styleTypes';

export interface StylesState {
    styles: Styles[];
    logo: string,
}

const initState = {
  styles: [],
  logo: '',
};

const stylesReducer = (
  state = initState,
  action: { type: string; payload: Styles[]; payload2: Styles; payload3: string},
): StylesState => {
  switch (action.type) {
    case 'GET_STYLES':
      return {
        ...state,
        styles: action.payload,
      };
    case 'GET_LOGO':
      return {
        ...state,
        logo: action.payload3,
      };
    case 'UPDATE_LOGO':
      return {
        ...state,
        logo: action.payload3,
      };
    case 'UPDATE_STYLES':
      return {
        ...state,
        styles: [...state.styles.filter((s:Styles) => s._id !== action.payload2._id), action.payload2] as Styles[],
      };
    default:
      return state;
  }
};

export default stylesReducer;
