import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamation,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';

type toggleButtonProps = {
    click:(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;  // eslint-disable-line
    selected: boolean,
    showAlerts:(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;  // eslint-disable-line
    showAnalytics:(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any; // eslint-disable-line
  };
  
const ToggleButton = ({ 
  showAlerts,
  showAnalytics,
  click,
  selected,
}: toggleButtonProps): JSX.Element => (
  <div className="toggle-button-container">
    <div className={`toggle-container ${selected ? 'alert' : 'stat'}`}>
      {selected 
        ? (
          <button 
            type="button" 
            onClick={showAnalytics} 
            className={selected ? 'word-or-icon-select' : ''}
            aria-label="show analytics toggle button"
            data-toggle="tooltip"
            data-placement="top"
            title="Toggle to show analytics"
          >
            Show Analytics
          </button>
        ) : null }
      <button 
        className={`toggle-button ${selected ? 'm-left' : 'm-right'}`} 
        onClick={click} 
        type="button" 
        aria-label={`${selected ? 'toggle button show analytics' : 'toggle button show alerts'}`}
      >
        <FontAwesomeIcon icon={selected ? faChartBar : faExclamation} />
      </button>
      {!selected 
        ? (
          <button 
            type="button" 
            onClick={showAlerts} 
            className={!selected ? 'word-or-icon' : ''}
            aria-label="show analytics toggle button"
            data-toggle="tooltip"
            data-placement="top"
            title="Toggle to show alerts"
          >
            Show Alerts
          </button>
        ) : null }
    </div>
  </div>
);
  
export default ToggleButton;
  
