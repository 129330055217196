import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FileType } from '../../Typings/fileTypes';
import { ToastType } from '../../Typings/toastTypes';
import SocketController from '../../Utils/SocketController';

const getFiles = async (dispatch: (action: { type: string; payload: FileType[] | ToastType }) => void): Promise<boolean> => {
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  let res: AxiosResponse<FileType[]>;
  try {
    res = await axios.get('/api/v2/core/files', config);
    if (res.status === 200) {
      dispatch({ type: 'GET_FILES', payload: res.data });
      return true;
    }
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'File Manager',
        message: 'Error fetching files',
        autoDismiss: true,
      },
    });
    return false;
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'File Manager',
        message: 'Error fetching files',
        autoDismiss: true,
      },
    });
    return false;
  }
};

const makeDelete = async (
  { delId, delName } : {delId: string, delName: string},
  dispatch: (action: { type: string; payload: FileType[] | ToastType | string }) => void, 
  socket: SocketController,
): Promise<boolean> => {
  let res: AxiosResponse<{ message: string }>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    res = await axios.delete(`/api/v2/core/files/${delId}`, config);
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'File Manager',
        message: `Error deleting ${delName} file`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error deleting ${delName} file`;
  if (res.status === 200) {
    const msg = res.data.message;
    socket.emitDeleteFile({ id: delId, msg });
    return true;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'File Manager',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};

const getImage = async (id: string): Promise<string | ArrayBuffer | null> => {
  const token = sessionStorage.getItem('authToken');
  const config: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` }, responseType: 'arraybuffer' };

  const result = await axios.get(`/api/v2/core/files/view/${id}`, config);

  const imageURL = btoa(new Uint8Array(result.data).reduce(
    (data, byte) => data + String.fromCharCode(byte),
    '',
  ));
  return `data:;base64,${imageURL}`;
};

const setImageArray = (file: FileType, 
  imageArr: { [key: string]: string | ArrayBuffer | null},
  dispatch: (action: { type: string, payload3: { id: string, url: string | ArrayBuffer | null}}) => void): void => {
  if (file.meta.mimetype.split('/')[0] === 'image') {
    if (imageArr[file._id]) {
      return;
    }
    if (file.url) {
      imageArr[file._id] = file.url; // eslint-disable-line no-param-reassign
    } else {
      getImage(file._id).then((res) => {
        imageArr[file._id] = res; // eslint-disable-line no-param-reassign
        dispatch({ type: 'UPDATE_URL', payload3: { id: file._id, url: res } });
      });
    }
  }
};

export default {
  getFiles, makeDelete, getImage, setImageArray, 
};
