import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../Redux/reducers';
import channelLogic from './channelLogic';
import AddEditModal from '../../Components/AddEditModal';
import DeleteModal from '../../Components/DeleteModal';
import { Channel } from '../../Typings/channelTypes';
import { ChannelCard } from '../../Components/Card';
import AddEditChannelPage from './AddEditChannelPage';
import SocketController from '../../Utils/SocketController';
import { ToastType } from '../../Typings/toastTypes';

export const renderChannelCards = (
  channels: Channel[],
  history: { push: (path: string) => void },
  setDelId: (id: string) => void,
  setDelName: (name: string) => void,
  setShowDeleteModal: (x: boolean) => void,
  setShowEditModal: (x: boolean) => void,
  setEditId: (id: string) => void,
): JSX.Element[] => {
  const channelList = channels.map((channel: Channel) => {
    const textColor = channel.color;
    const backgroundColor = channel.style;
    const channelContent = [
      {
        heading: channel.name,
        data: [],
      },
    ];
    if (!channel._id) return <></>;
    return (
      <ChannelCard
        key={channel._id}
        id={channel._id}
        handleDelete={(id) => { setDelId(id); setDelName(channel.name); setShowDeleteModal(true); }}
        handleEdit={(id) => { setEditId(id); setShowEditModal(true); }}
        content={channelContent}
        backgroundColor={backgroundColor}
        textColor={textColor}
        ariaLabel={channel.name}
      />
    );
  });
  return channelList;
};

export const handleDelete = async (
  { delId, delName } : {delId: string, delName: string},
  dispatch: (action: { type: string, payload?: Channel[] | ToastType, payload3?: string }) => void, 
  socket: SocketController,
):Promise<boolean> => {
  if (!delId.length) return false;
  const res = await channelLogic.deleteChannel({ id: delId, delName }, dispatch, socket);
  return res;
};

const ChannelContainer = (): JSX.Element => {
  const { channels, channelsVisited } = useSelector((state: RootState) => state.channel);
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const dispatch = useDispatch();
  const history = useHistory();
  const [delName, setDelName] = useState('');
  const [delId, setDelId] = useState('');
  const [editId, setEditId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    if (!channelsVisited || !channels.length) channelLogic.getChannels(dispatch);
  }, []);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleShowAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  return (
    <main className="outerContainer">
      <section>
        <header 
          tabIndex={0} // eslint-disable-line
          aria-label="Channels Page" 
          className="sectionHeading addSectionHeading"
        >
          <h1>Channels</h1>
          <div className="addBtnContainer">
            <FontAwesomeIcon 
              aria-label="Add Channel Button"
              onKeyPress={() => toggleShowAddModal()}
              role="button"
              tabIndex={0} // eslint-disable-line
              icon={faPlusCircle} 
              color="#6DABFF" 
              size="2x" 
              className="addRoleBtn mr-3" 
              onClick={() => toggleShowAddModal()} 
            />
          </div>
        </header>
      </section>
      { showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          deleteTitle="Channel"
          deleteFunction={() => handleDelete({ delId, delName }, dispatch, socket)}
          toggleModal={toggleDeleteModal}
        />
      )}
      { showAddModal && (
        <AddEditModal
          open={showAddModal}
          modalHeading="Add Channel"
          modalBody={<AddEditChannelPage toggle={toggleShowAddModal} />}
          toggleModal={toggleShowAddModal}
        />
      )}
      { showEditModal && (
        <AddEditModal
          open={showEditModal}
          modalHeading="Edit Channel"
          modalBody={<AddEditChannelPage toggle={toggleEditModal} id={editId} />}
          toggleModal={toggleEditModal}
        />
      )}
      <div className="card-container">
        {channels.length ? renderChannelCards(channels, history, setDelId, setDelName, toggleDeleteModal, toggleEditModal, setEditId) : null }
      </div>
    </main>
  );
};

export default ChannelContainer;
