import { User } from '../../Typings/userTypes';

type UserState = {
    users: User[];
    usersVisited: boolean;
};

const initialState: UserState = {
  users: [],
  usersVisited: false,
};

export function userReducer(
  state = initialState,
  action: { type: string, payload: User[] | string, payload2: User },
): UserState {
  switch (action.type) {
    case 'GET_USERS':
      return {
        ...state,
        users: action.payload as User[],
      };
    case 'ADD_USER_SUCCESS':
      return {
        ...state,
        users: [...state.users, action.payload] as User[],
      };
    case 'EDIT_USER_SUCCESS':
      return {
        ...state,
        users: [...state.users.filter((u: User) => u._id !== action.payload2._id), action.payload2] as User[],
      };
    case 'DELETE_USER_SUCCESS':
      return {
        ...state,
        users: state.users.filter((u: User) => u._id !== action.payload),
      };
    case 'VISIT_USERS':
      return {
        ...state,
        usersVisited: true,
      };
    default:
      return state;
  }
}

