import * as React from 'react';
import { DashboardContextType } from '../Typings/contextTypes';

export const DashboardContext = React.createContext<DashboardContextType | undefined>(undefined);

export const useDashboardContext = (): DashboardContextType => {
  const context = React.useContext(DashboardContext);
    
  // Error 
  if (context === undefined) {
    throw Error(
      'This component must be used inside of Dashboard, '
          + 'otherwise it will not function correctly.',
    );
  }
  
  return context;
};

export default DashboardContext;
