import {
  Role, AccessControls, TokenType, 
} from '../../Typings/adminTypes';
import { Channel } from '../../Typings/channelTypes';

type adminActions = {
    payload: string[] | Channel[] | Role[] | AccessControls[] | TokenType[] | TokenType | number | string,
    payload2: Role | TokenType,
    type: string
}

export interface adminState {
    roles: Role[];
    accessControls: AccessControls[];
    permissions: string[];
    tokens: TokenType[];
    rolesVisited: boolean;
    tokensVisited: boolean;
}

const initState = {
  roles: [],
  accessControls: [],
  permissions: [
    'send_message',
    'sender',
    'user',
    'alert',
    'channel',
    'device',
    'file',
    'message',
    'role',
    'styles',
    'system',
    'token',
    'none',
    'transaction',
    'geo_fetch_devices',
    'geo_send_alert',
  ],
  tokens: [],
  rolesVisited: false,
  tokensVisited: false,
};

export const adminReducer = (state: adminState = initState, action: adminActions): adminState => {
  switch (action.type) {
    case 'GET_ROLES':
      return {
        ...state,
        roles: action.payload as Role[],
      };
    case 'GET_ACCESS_CONTROLS':
      return {
        ...state,
        accessControls: action.payload as AccessControls[],
      };

    case 'EDIT_ROLE':
      return {
        ...state,
        roles: [...state.roles.filter((cur:Role) => cur._id !== action.payload2._id), action.payload2] as Role[],
      };

    case 'ADD_ROLE':
      return {
        ...state,
        roles: [...state.roles, action.payload2] as Role[],
      };

    case 'GET_PERMISSIONS': 
      return {
        ...state,
        permissions: action.payload as string[],
      };

    case 'DELETE_ROLES':
      return {
        ...state,
        roles: state.roles.filter((cur:Role) => cur._id !== action.payload),
      };

    case 'GET_TOKENS':
      return {
        ...state,
        tokens: action.payload as TokenType[],
      };

    case 'ADD_TOKEN':
      return {
        ...state,
        tokens: [...state.tokens, action.payload] as TokenType[],
      };

    case 'REFRESH_TOKEN':
      return {
        ...state,
        tokens: [action.payload2, ...state.tokens.filter((cur: TokenType) => cur._id !== action.payload2._id)] as TokenType[],
      };

    case 'DELETE_TOKENS':
      return {
        ...state,
        tokens: state.tokens.filter((cur: TokenType) => cur._id !== action.payload),
      };

    case 'VISIT_ROLES':
      return {
        ...state,
        rolesVisited: true,
      };

    case 'VISIT_TOKENS':
      return {
        ...state,
        tokensVisited: true,
      };
    default:
      return state;
  }
};

