import React from 'react';

export type StatusProps = {
  message: string;
  type: string;
};

const Status = ({ message, type }: StatusProps): JSX.Element => {
  if (
    message !== undefined
    && message !== ''
    && type !== undefined
    && type !== ''
  ) {
    let classname: string = type;
    if (
      (type !== 'success' && type !== 'warning' && type !== 'error')
      || type === 'error'
    ) classname = 'danger';
    return (
      <div className={`alert alert-${classname} text-center`} role="alert" aria-live="assertive">
        {message}
      </div>
    );
  }
  return <></>;
};

export default Status;
