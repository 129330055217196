import moment from 'moment';
import React from 'react';
import { useDashboardContext } from '../Context/dashboardContext';
import { AlertDetail } from '../Typings/alertTypes';
import Donut from './DonutChart';

type SingleAlertAnalyticProps = {
  alertDetail: AlertDetail
};

const SingleAlertAnalytics = ({ alertDetail }: SingleAlertAnalyticProps): JSX.Element => {
  const acknowledgedData = [
    { value: alertDetail.devicesReceived.length, stroke: '#b1d4e9' }, // should be larger value i.e: total sent
    { value: alertDetail.acknowledged.length, stroke: '#0075c9' }, // should be smaller value i.e: total acknowledged
  ];

  const { renderChannels } = useDashboardContext();

  return (
    <div className="singleAlertContainer">
      <div className="card singleAlertHeaderCard">
        <div className="singleAlertHeader">

          <p className="singleAlertTitle">
            {alertDetail.content}
          </p>
          <div className="singleAlertPillContainer">{renderChannels(alertDetail)}</div>
        </div>
        <div className="singleAlertHeader">
          <p className="timestamp">
            {moment(alertDetail.sent).format('MMMM Do YYYY, h:mm a')}
          </p>
        </div>
      </div>
      <div className="singleAlertContentContainer">
        <div className="donutStatsContainer">
          <Donut data={acknowledgedData} heading="Acknowledged" />
        </div>
        <div className="singleAlertStatContainer">
          <div className="card singleAlertCard">
            <p className="singleAlertCardNumber">
              {alertDetail.devicesReceived.length} 
            </p>
            <p className="singleAlertCardNumberFont">
              devices received selected alert
            </p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SingleAlertAnalytics;
