import React from 'react';
import {
  Modal, ModalBody, ModalFooter, 
} from 'reactstrap';
import Button from './Button';

export type DeleteModalProps = {
  open: boolean;
  toggleModal: () => void;
  deleteTitle: string;
  deleteFunction: () => Promise<boolean> | void;
};

const DeleteModal = ({
  open,
  toggleModal,
  deleteTitle,
  deleteFunction,
}: DeleteModalProps): JSX.Element => {
  const deleteAndToggle = (): boolean => {
    deleteFunction();
    toggleModal();
    return true;
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggleModal}>
        <h2 className="modal-header">{`Delete ${deleteTitle}`}</h2>
        <ModalBody>
          <p> Are you sure you want to delete? </p>
        </ModalBody>
        <ModalFooter>
          <Button
            text="Cancel"
            className="btn btn-outline-danger"
            onClick={toggleModal}
          />
          <Button
            text="Confirm"
            className="btn btn-outline-secondary"
            onClick={deleteAndToggle}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteModal;
