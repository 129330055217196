import { Alert, AlertDetail } from '../../Typings/alertTypes';
import { Channel } from '../../Typings/channelTypes';

export interface DashboardState {
  alerts: Alert[];
  filteredAlerts: boolean;
  totalAlerts: Alert[];
  activeUsers: number;
  registeredDevices: number;
  alertDetail: AlertDetail | undefined
}

const initState: DashboardState = {
  alerts: [],
  filteredAlerts: false,
  totalAlerts: [],
  activeUsers: 1,
  registeredDevices: 0,
  alertDetail: undefined,
};

const dashboardReducer = (
  state = initState,
  action: { type: string; payload: Alert[] | Channel[] | number | AlertDetail },
): DashboardState => {
  switch (action.type) {
    case 'GET_ALERTS_SUCCESS':
      return {
        ...state,
        alerts: action.payload as Alert[],
        filteredAlerts: false,
      };
    case 'GET_FILTERED_ALERTS_SUCCESS':
      return {
        ...state,
        alerts: action.payload as Alert[],
        filteredAlerts: true,
      };
    case 'ACTIVE_USERS':
      return {
        ...state,
        activeUsers: action.payload as number,
      };
    case 'REGISTERED_DEVICES':
      return {
        ...state,
        registeredDevices: action.payload as number,
      };
    case 'GET_ALERT_DETAIL':
      return {
        ...state,
        alertDetail: action.payload as AlertDetail,
      };
    case 'CLEAR_ALERT_DETAIL':
      return {
        ...state,
        alertDetail: undefined,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
