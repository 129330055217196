
import { Copyright, CopyrightState } from '../../Typings/copyrightTypes';

const initialState: CopyrightState = {
  copyrights: {
    version: '3.1.0',
    model: 'V-ALERT',
    copyright: '2021',
  },
};

const copyrightReducer = (
  state: CopyrightState = initialState, 
  action:{type:string; payload:Copyright},
): CopyrightState => {
  switch (action.type) {
    default:
      return state;
  }
};

export default copyrightReducer;

