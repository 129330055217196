import React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/reducers';
import Button from '../../Components/Button';
import ChannelSelectorComponent from '../../Components/ChannelSelector';
import { Channel } from '../../Typings/channelTypes';
import Pill from '../../Components/Pill';
import channelLogic from '../Channels/channelLogic';
import { ToastType } from '../../Typings/toastTypes';
import SocketController from '../../Utils/SocketController';

const senderUrl = '/api/v2/sender/sender/';

export const submitAlert = async (
  channels: Channel[],
  messageText: string,
  setChannelPills: React.Dispatch<React.SetStateAction<Channel[]>>,
  setMessageText: (message: string) => void,
  dispatch: (action: { type: string, payload: ToastType }) => void,
  socket: SocketController,
): Promise<boolean> => {
  const channelNames = channels.map((channel: Channel) => {
    const channelName = channel.name;
    return channelName;
  });

  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  const payload = { channels: channelNames, message: messageText };
  try {
    const res = await axios.post(senderUrl, payload, config);
    const errMessage = res.status === 401 ? 'Unauthorized' : 'Error sending alert';
    if (res.status === 201) {
      socket.emitNewAlert(res.data);
      dispatch({
        type: 'ADD_TOAST', 
        payload: { 
          toastType: 'success', 
          heading: 'Sender', 
          message: 'Alert was successfully sent',
          autoDismiss: true,        
        },
      });
      setMessageText('');
      setChannelPills([]);
      return true;
    }
    dispatch({
      type: 'ADD_TOAST', 
      payload: { 
        toastType: 'danger', 
        heading: 'Sender', 
        message: `${errMessage}`,
        autoDismiss: true,        
      },
    });
    return false;
  } catch (e) {
    dispatch({
      type: 'ADD_TOAST', 
      payload: { 
        toastType: 'danger', 
        heading: 'Sender', 
        message: 'Error sending alert',
        autoDismiss: true,        
      },
    });
    return false;
  }
};

export const messageMissing: (messageText: string, channelPills: Channel[]) => boolean = (messageText, channelPills) => {
  if (messageText.length > 0 && (channelPills.length > 0)) {
    return false;
  }
  return true;
};

const SenderContainer = (): JSX.Element => {
  // TODO: display status messages when there is a value in the message state. 
  // TODO: Save message object to sessionStorage to repopulate when navigate back to sender.
  const dispatch = useDispatch();
  const [channelPills, setChannelPills] = React.useState<Channel[]>([]);
  const [messageText, setMessageText] = React.useState('');
  const { channels } = useSelector((state: RootState) => state.channel);
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line

  React.useEffect(() => {
    channelLogic.getChannels(dispatch);
  }, []);

  const renderPills = () => (
    <>
      <div className="recipientLabel">Recipients:</div>
      {channelPills.map((channel: Channel) => (
        <Pill
          key={`${channel.name}-senderPill`}
          content={channel.name}
          backgroundColor={channel.style}
          textColor={channel.color}
        />
      ))}
    </>
  );

  return (
    <div className="outerContainer">
      <main className="senderBody">
        <section className="senderLeft">
          <header 
            aria-label="Send Alerts Page" 
            tabIndex={0} // eslint-disable-line
            className="sectionHeading"
          >
            <h1>Send Alerts</h1>
          </header>
          <ChannelSelectorComponent channels={channels} setChannelPills={setChannelPills} channelPills={channelPills} sender />
        </section>
        <div className="card senderContainer">
          <div className="pillsContainer">
            {renderPills()}
          </div>
          <textarea
            name="message"
            form="senderForm"
            className="senderText"
            placeholder="Message text..."
            value={messageText}
            maxLength={1800}
            onChange={(e) => setMessageText(e.target.value)}
            aria-label="This is the message text area on the send alerts page. The send button is disabled until a message is typed."
          />
          <Button
            text="SEND"
            onClick={() => submitAlert(channelPills, messageText, setChannelPills, setMessageText, dispatch, socket)}
            className="senderButton primary-button"
            type="submit"
            disabled={messageMissing(messageText, channelPills)}
            aria-label="send message button"
            aria-disabled="true"
          />
        </div>
      </main>
    </div>
  );
};

export default SenderContainer;
