import React from 'react';
import {
  Modal, ModalBody, 
} from 'reactstrap';

export type AddEditModalProps = {
  open: boolean;
  toggleModal: () => void;
  modalHeading: string;
  modalBody: JSX.Element;
};

const AddEditModal = ({
  open,
  toggleModal,
  modalHeading,
  modalBody,
}: AddEditModalProps): JSX.Element => (
  <div>
    <Modal isOpen={open} toggle={toggleModal}>
      <h2 className="modal-header addEditModalHeader">{modalHeading}</h2>
      <ModalBody>
        {modalBody}
      </ModalBody>
    </Modal>
  </div>
);

export default AddEditModal;
