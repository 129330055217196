import React, { useState } from 'react';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import Button from '../../Components/Button';
import { ToastType } from '../../Typings/toastTypes';

export type PassChangeModalProps = {
  id: string;
  toggleModal: () => void;
  open: boolean;
};

export type PassData = {
  passwordReset: boolean,
  password?: string
};

const PassChangeModal = ({ 
  id, 
  toggleModal,
  open,
}: PassChangeModalProps): JSX.Element => {
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const dispatch: (action: { type: string; payload: ToastType}) => void = useDispatch();

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmedPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmedPassword(e.target.value);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    if (e !== undefined) e.preventDefault();
    const token = sessionStorage.getItem('authToken');
    const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
    const data: PassData = {
      passwordReset: true,
    };
    if (!isChecked) data.password = password;
    try {
      const res = await axios.put(`/api/v2/user/users/${id}`, data, config);
      if (res.status === 200) {
        dispatch({
          type: 'ADD_TOAST',
          payload: {
            toastType: 'success',
            heading: 'Users',
            message: 'Successfully edited password',
            autoDismiss: true,
          },
        });
      } else {
        dispatch({
          type: 'ADD_TOAST',
          payload: {
            toastType: 'danger',
            heading: 'Users',
            message: 'Error editing password',
            autoDismiss: true,
          },
        });
        toggleModal();
        return false;
      }
    } catch (err) {
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          toastType: 'danger',
          heading: 'Users',
          message: 'Error editing password',
          autoDismiss: true,
        },
      });
      toggleModal();
      return false;
    }
    toggleModal();
    return true;
  };

  const valdiateForm = ():boolean => {
    const testPassOnlySpace = /[a-zA-Z0-9]/.test(password);
    if (isChecked) {
      return false;
    } 
    if (!testPassOnlySpace) return true;
    if (password === undefined || password === null || password === '' || password.length < 8) return true;
    if (password !== confirmedPassword) return true;
    return false;
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggleModal} keyboard={false} backdrop="static">
        <ModalHeader>
          <p className="h2 addEditModalHeader">Change Password</p>
          <p className="minorPrint mb-0">It is recommended that you change the default administrator password.</p> 
        </ModalHeader>
        <ModalBody>
          <div className="outerFormContainer">
            <div className="form-group">
              <div className="input-label">Password</div>
              <p className="minorPrint">Password must be at least 8 characters long.</p>
              <input
                className="form-control"
                type="password"
                placeholder="********"
                value={password}
                onChange={(e) => handlePasswordInput(e)}
                aria-label="password"
                disabled={isChecked}
              /> 
            </div>
            <div className="form-group">
              <div className="input-label">Confirm Password</div>
              <input
                className="form-control"
                type="password"
                placeholder="********"
                value={confirmedPassword}
                onChange={(e) => handleConfirmedPasswordInput(e)}
                aria-label="password"
                disabled={isChecked}
              /> 
            </div>
            <div className="form-group">
              <div className="input-label">Do not change password</div>
              <p className="minorPrint mb-0">Passwords can be changed on the Users page.</p>
              <input  
                className="checkbox"
                type="checkbox"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                aria-label="admin checkbox"
              />
            </div>
            <div className="form-group">
              <Button
                text="Change Password"
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => handleSubmit(e)}
                disabled={valdiateForm()}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div> 
  );
};

export default PassChangeModal;
