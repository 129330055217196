/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import Login, { hasAccess } from './Containers/LoginContainer';
import AppFrame from './AppFrame';
import Users from './Containers/User/User';
import Dashboard from './Containers/Dashboard/Dashboard';
import Channel from './Containers/Channels/ChannelContainer';
import Admin from './Containers/Admin/Admin';
import FileManager from './Containers/FileManager/FileManager';
import Sender from './Containers/Sender/SenderContainer';
import NotFoundPage from './Containers/404Page';
import StylesContainer from './Containers/Style/Styles';

interface Props {
  component: React.ComponentType;
  path?: string | undefined;
  exact?: boolean | undefined;
  permissions?: string[];
}

export const PrivateRoute = ({
  component: Component, path, permissions, ...rest 
}: Props): JSX.Element => {
  const loggedIn = sessionStorage.getItem('authToken');
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps | any): JSX.Element => ((loggedIn && path && permissions && hasAccess(path, permissions)) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }, // eslint-disable-line
          }}
        />
      ))
      }
    />
  );
};

const Router = ({ parsedPermissions }: {parsedPermissions: string[]}): JSX.Element => (
  <AppFrame>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} permissions={parsedPermissions} />
      <PrivateRoute exact path="/sender" component={Sender} permissions={parsedPermissions} />
      <PrivateRoute exact path="/users" component={Users} permissions={parsedPermissions} />
      <PrivateRoute exact path="/channels" component={Channel} permissions={parsedPermissions} />
      <PrivateRoute exact path="/files" component={FileManager} permissions={parsedPermissions} />
      <PrivateRoute exact path="/admin" component={Admin} permissions={parsedPermissions} />
      <PrivateRoute exact path="/styles" component={StylesContainer} permissions={parsedPermissions} />
      <Route component={NotFoundPage} />
    </Switch>
  </AppFrame>
);

export default connect(null, null)(Router);
