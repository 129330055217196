/* eslint no-nested-ternary: "off" */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DropdownMenu, DropdownItem, Dropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import dashboardLogic, { AlertFetchType } from './dashboardLogic';
import usersLogic from '../User/usersLogic';
import Button from '../../Components/Button';
import { Alert, AlertDetail } from '../../Typings/alertTypes';
import { Channel } from '../../Typings/channelTypes';
import { RootState } from '../../Redux/reducers';
import ToggleButton from '../../Components/toggleButton';
import { DashboardContext } from '../../Context/dashboardContext';
import channelLogic from '../Channels/channelLogic';
import ChannelSelectorComponent from '../../Components/ChannelSelector';
import { fetchRegisteredDevices } from '../../Utils/deviceUtils';
import Analytics from '../../Components/Analytics';
import AlertCard from '../../Components/AlertCard';
import Calendar from '../../Components/Calendar';
import DateSeparator from '../../Components/DateSeparator';
import Pill from '../../Components/Pill';
import PassChangeModal from './passChangeModal';
import SingleAlertAnalytics from '../../Components/SingleAlertAnalytics';
import Status from '../../Components/Status';

export const renderAlertSection = (alerts: Alert[]): JSX.Element => {
  let showSeparator = false,
    prevSent = moment(alerts[0].sent).format('DD-MM-YY');

  const allAlerts = alerts.map((alert: Alert): JSX.Element => {
    if (moment(alert.sent).format('DD-MM-YY') !== prevSent) {
      showSeparator = true;
      prevSent = moment(alert.sent).format('DD-MM-YY');
    } else if (alerts[0] === alert) {
      showSeparator = true;
    } else {
      showSeparator = false;
    }

    return (
      <div key={`${alert._id}outerDiv`}>
        {showSeparator ? <DateSeparator date={alert.sent} /> : null}
        <AlertCard key={alert._id} alert={alert} />
      </div>
    );
  });
  return <div id="alertContainer" className="alertContainer">{allAlerts}</div>;
};

export const renderStatsSection = (alerts: Alert[]): JSX.Element => (
  <div className="statSection">
    <Analytics
      totalSent={alerts.reduce((sum, currentAlert) => sum + currentAlert.recipients.length, 0)}
      totalAcknowledged={alerts.reduce((sum, currentAlert) => sum + currentAlert.acknowledged.length, 0)}
    />
  </div>
);

export const renderChannels = (alert: Alert | AlertDetail): JSX.Element => {
  const channelList = alert.channels.map((channel: string) => (
    <Pill
      key={`${alert._id}${channel}`}
      content={channel}
      backgroundColor="#06376C"
      textColor="white"
    />
  ));
  return <>{channelList}</>;
};

export const renderAlertDetailsSection = (alertDetail: AlertDetail | undefined): JSX.Element => {
  if (alertDetail !== undefined) {
    return (
      <>
        <SingleAlertAnalytics alertDetail={alertDetail} />
        {' '}
      </>
    );
  }
  return <></>;
};

const DashboardContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [adminId, setAdminId] = useState('');
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [totalDocs, setTotalDocs] = useState(10);
  const [errorMessage, setErrorMessage] = useState('');
  const [isToggled, setToggled] = useState(true);
  const [showingByDate, setShowingByDate] = useState(false);
  const [channelPills, setChannelPills] = React.useState<Channel[]>([]);
  const [dateAlerts, setDateAlerts] = useState<Alert[]>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [dropdownOption, setDropDownOption] = useState('Filter by: text');
  const [showPassModal, setShowPassModal] = useState(false);
  const [passModalCalled, setPassModalCalled] = useState(false);
  const {
    alerts, activeUsers, registeredDevices, filteredAlerts, alertDetail,
  } = useSelector((state: RootState) => state.dashboard);
  const { channels } = useSelector((state: RootState) => state.channel);

  // Helpers
  const setSuccessfulStates = (res?: AlertFetchType): boolean => {
    setErrorMessage('');
    if (res?.data) {
      setCurrent(res.data?.page);
      setHasNextPage(res.data?.hasNextPage);
      setHasPrevious(res.data?.hasPrevPage);
      setTotalDocs(res.data?.totalDocs);
    }
    return true;
  };

  const renderPills = () => (
    <div className="form-control search-filter-pills">
      {channelPills.map((channel: Channel) => (
        <Pill
          key={`${channel.name}-senderPill`}
          content={channel.name}
          backgroundColor={channel.style}
          textColor={channel.color}
        />
      ))}
    </div>
  );

  const buttonToggled = () => setToggled(!isToggled);
  const dropdownToggle = () => setDropDownOpen(!dropdownToggle);
  const togglePassModal = () => { setShowPassModal(!showPassModal); };

  const updateAlertDetailsDisplay = (alertId: string) => {
    if (alertId === '') {
      dashboardLogic.clearAlertDetail({ dispatch });
    } else {
      dashboardLogic.getAlertDetail({
        dispatch, setErrorMessage, setSuccessfulStates, alertId,
      });
    }
  };

  // Use effects
  useEffect(() => {
    dashboardLogic.getNextPageOfAlerts({
      dispatch, setErrorMessage, setSuccessfulStates, current,
    });
    if (registeredDevices === 0) fetchRegisteredDevices(dispatch);
  }, []);

  useEffect(() => {
    channelLogic.getChannels(dispatch);
  }, []);

  useEffect(() => {
    const userID = sessionStorage.getItem('authorizedUser');
    if ((userID !== null && userID !== undefined) && passModalCalled === false) {
      setPassModalCalled(true);
      setAdminId(userID);
      usersLogic.checkAdminPassword(userID, setShowPassModal);
    }
  });

  // UI conditional containers

  const filterContainer = showingByDate !== true
    ? (
      <form>
        <div className={dropdownOption === 'Filter By: text' ? 'input-group mb-3' : 'input-group mb-3 search-filter'}>
          <div className="input-group-prepend">
            <Dropdown toggle={dropdownToggle} isOpen={(dropdownOpen)}>
              <button
                className="btn primary-button dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {dropdownOption ? `${dropdownOption}` : 'Filter By:'}
              </button>
              <DropdownMenu>
                <DropdownItem
                  onClick={
                    () => {
                      setDropDownOption('Filter by: text'); dashboardLogic.getNextPageOfAlerts({
                        dispatch, setErrorMessage, setSuccessfulStates, current: 0,
                      });
                    }
                  }
                >
                  Reset Filter
                </DropdownItem>
                <DropdownItem
                  onClick={() => { setDropDownOption('Filter by: channels'); setSearchFilter(''); }}
                >
                  Channels
                </DropdownItem>
                <DropdownItem
                  onClick={() => { setDropDownOption('Filter by: text'); setChannelPills([]); }}
                >
                  Text
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          {
            dropdownOption === 'Filter by: channels' ? (
              <div className="input-group-prepend">
                <ChannelSelectorComponent
                  channels={channels}
                  setChannelPills={setChannelPills}
                  channelPills={channelPills}
                  className="search-filter-selector"
                />
                {renderPills()}
              </div>
            ) : null
          }
          {
            dropdownOption === 'Filter by: text' ? (
              <input 
                type="text" 
                className="form-control" 
                onChange={(e) => { setSearchFilter(e.target.value); }} 
                value={searchFilter} 
                aria-label="This is a text filter input field. The 'search' button is disabled until something is typed in this field" 
              />
            ) : null
          }
          <div className="input-group-append">
            <Button
              type="submit"
              text="Search"
              disabled={!!(searchFilter === '' && !channelPills.length)}
              ariaLabel="filter channels"
              onClick={(e) => { dashboardLogic.fetchQueriedAlerts(e, dispatch, current, channelPills, searchFilter, setErrorMessage); }}
            />
          </div>
        </div>
      </form>
    )
    : null;

  const errorContainer = isToggled === true && errorMessage.length
    ? (<div><Status type="error" message={errorMessage} /></div>)
    : isToggled === false && errorMessage.length
      ? (<div><Status type="error" message={`${errorMessage} to show statistics`} /></div>)
      : null;

  const warningContainer = isToggled === true && !alerts.length
    ? (<div><Status type="warning" message="There are no alerts to display" /></div>)
    : isToggled === false && !alerts.length
      ? (
        <div>
          <Status type="warning" message="No alerts available for which to show statistics" />
        </div>
      )
      : null;

  const mainContainer = alertDetail !== undefined && (alerts.length || dateAlerts.length)
    ? renderAlertDetailsSection(alertDetail)
    : (alerts.length && !showingByDate)
      ? (isToggled
        ? renderAlertSection(alerts)
        : renderStatsSection(alerts)
      )
      : (dateAlerts.length && showingByDate)
        ? (isToggled
          ? renderAlertSection(dateAlerts)
          : renderStatsSection(dateAlerts)
        )
        : null;

  const pageNavContainer = showingByDate || filteredAlerts || alertDetail
    ? null
    : (
      <div className="alertSectionInfo">
        <p>
          {current === 1 ? current : (current * 10) - 10}
          {' '}
          -
          {' '}
          {(current * 10) < totalDocs ? (current * 10) : totalDocs}
          {' '}
          of
          {' '}
          {totalDocs}
        </p>
        <div className="alertNavButtons">
          <Button
            className="primary-button prevPageButton"
            text={(
              <FontAwesomeIcon
                icon={faArrowLeft}
              />
            )}
            disabled={!hasPrevious}
            onClick={() => dashboardLogic.getPrevPageOfAlerts({
              dispatch, setErrorMessage, setSuccessfulStates, current,
            })}
            ariaLabel="previous page"
          />
          <Button
            className="primary-button nextPageButton"
            text={(
              <FontAwesomeIcon
                icon={faArrowRight}
              />
            )}
            disabled={!hasNextPage}
            onClick={() => dashboardLogic.getNextPageOfAlerts({
              dispatch, setErrorMessage, setSuccessfulStates, current,
            })}
            ariaLabel="next page"
          />
        </div>
      </div>
    );

  // Return
  return (
    <DashboardContext.Provider value={{ updateAlertDetailsDisplay, renderChannels }}>
      <div className="dashboardContainer">
        <main className="outerFlexContainer">
          <section className="alertSection">
            <header 
              className="sectionHeading dashboardHeading"
              aria-label={isToggled === true ? 'Recent Alerts Page' : 'Analytics Page'}
            >
              {isToggled === true
                ? (
                  <h1 
                    id="recentAlertsHeading"
                    tabIndex={0} //eslint-disable-line
                  >
                    Recent Alerts
                  </h1>
                )
                : (
                  <h1 
                    id="analyticsHeading"
                    tabIndex={0} //eslint-disable-line
                  >
                    Analytics
                  </h1>
                )}
              {alertDetail !== undefined
                ? <Button className="primary-button returnButton" text="Return to Alerts" onClick={() => updateAlertDetailsDisplay('')} />
                : <ToggleButton showAlerts={buttonToggled} showAnalytics={buttonToggled} click={buttonToggled} selected={isToggled} />
              }
            </header>
            {filterContainer}
            {errorContainer}
            {warningContainer}
            {mainContainer}
            {pageNavContainer}
          </section>
          <section className="rightSection">
            <div className="calendarSection">
              <Calendar setAlerts={setDateAlerts} showByDate={setShowingByDate} setErrorMessage={setErrorMessage} />
            </div>
            <div 
              className="deviceStatsContainer"
              tabIndex={0} //eslint-disable-line
            >
              <div className="card statCard">
                <p className="activeUserNumber">
                  {activeUsers}
                </p>
                <p 
                  className="activeUserTitle"
                  aria-label="Number of Active Users"
                >
                  Active Users
                </p>
              </div>
              <div 
                className="card statCard"
                tabIndex={0} // eslint-disable-line
              >
                <p className="registeredDevicesNumber">
                  {registeredDevices}
                </p>
                <p 
                  className="registeredDevicesTitle"
                  aria-label="Number of Registered Devices"
                >
                  Registered Devices
                </p>
              </div>
            </div>
          </section>
          <section>
            {showPassModal && (
              <PassChangeModal
                id={adminId}
                toggleModal={togglePassModal}
                open={showPassModal}
              />
            )}
          </section>
        </main>
      </div>
    </DashboardContext.Provider>
  );
};

export default DashboardContainer;
