import React from 'react';
import Background from '../assets/404-page.png';
import Animation from '../assets/404-page.gif';
import Button from '../Components/Button';

const NotFoundPage = (): JSX.Element => {
  const handleClick = (url:string) => {
    window.location.href = url;
    return true;
  };
  
  return (
    <main className="notFoundPageWrapper">
      <h1 className="hidden-h1">404 Page</h1>
      <img className="notFoundPageBackground" src={Background} alt="404" />
      <div className="notFoundContentWrapper">
        <img className="notFoundPage" src={Animation} alt="404" />
        <h2 className="notFoundText">Sorry could not reach page.</h2>
        <Button type="submit" className="notFoundButton primary-button" text="Go to Dashboard" onClick={() => handleClick('/dashboard')} />
      </div>
    </main>
  );
};

export default NotFoundPage;
