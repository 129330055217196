import React from 'react';

type PillProps = {
  content: string;
  textColor: string;
  backgroundColor: string;
};

const Pill = ({
  content,
  backgroundColor,
  textColor,
}: PillProps): JSX.Element => (
  <div style={{ backgroundColor }} className="pill">
    <p style={{ color: textColor, textAlign: 'center' }}>{content}</p>
  </div>
);

export default Pill;
