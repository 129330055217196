import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Router from './Router';
import SocketController from './Utils/SocketController';
import { fetchRegisteredDevices } from './Utils/deviceUtils';
import { RootState } from './Redux/reducers/index';

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const loggedIn = sessionStorage.getItem('authToken');
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line

  const decodedPermissions: string | null = sessionStorage.getItem('permissions');
  let parsedPermissions: string[];
  if (decodedPermissions !== null) {
    parsedPermissions = JSON.parse(decodedPermissions) as string[];
  } else parsedPermissions = [''];

  React.useEffect(() => {
    if (!socket.emitCurrent) {
      dispatch({ type: 'NEW_SOCKET', payload: new SocketController(dispatch) });
      if (loggedIn && socket.emitCurrent) {
        socket.emitCurrent(); // eslint-disable-line @typescript-eslint/no-unsafe-call
      }
    }
  }, []);

  React.useEffect(() => {
    if (loggedIn && socket.emitCurrent) {
      socket.emitCurrent(); // eslint-disable-line @typescript-eslint/no-unsafe-call
      socket.emitActiveUsers(); // eslint-disable-line @typescript-eslint/no-unsafe-call
      fetchRegisteredDevices(dispatch);
    }
  }, [loggedIn, socket]);

  return (
    <div id="App">
      <Router parsedPermissions={parsedPermissions} />
    </div>
  );
};
export default withRouter(connect(null, null)(App));
