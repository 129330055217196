import React from 'react';
import Donut from './DonutChart';

type AnalyticProps = {
  totalSent: number,
  totalAcknowledged: number,
};

const Analytics = ({ totalSent, totalAcknowledged }: AnalyticProps): JSX.Element => {
  const acknowledgedData = [
    { value: totalSent, stroke: '#b1d4e9' }, // should be larger value i.e: total sent
    { value: totalAcknowledged, stroke: '#0075c9' }, // should be smaller value i.e: total acknowledged
  ];

  // const receivedData = [
  //   { value: totalSent, stroke: '#b1d4e9' }, // should be larger value i.e: total recipients
  //   { value: totalReceived, stroke: '#0075c9' }, // should be smaller value i.e: total received
  // ];

  return (
    <div className="analyticsContainer">
      <div 
        tabIndex={0} // eslint-disable-line
        className="card analyticsCard"
      >
        <p aria-label={`Total devices messages sent to: ${totalSent}`} className="analyticsCardTitle">
          Total devices messages sent to:
        </p>
        <p className="analyticsCardNumber">
          {totalSent}
        </p>
      </div>
      <div 
        tabIndex={0} // eslint-disable-line
        className="donutStatsContainer"
      >
        <Donut data={acknowledgedData} heading="Acknowledged" />
        {/* <Donut data={receivedData} heading="Received" /> */}
      </div>
    </div>
  );
};

export default Analytics;
