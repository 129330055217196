import React from 'react';

type ButtonProps = {
  text: string | JSX.Element;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any; // eslint-disable-line
  className?: string;
  id?: string;
  type?: 'submit' | 'button' | '';
  disabled?: boolean | undefined;
  ariaLabel?: string;
  ariaDisabled?: boolean;
};

const Button = ({
  text,
  onClick,
  className,
  id,
  type,
  disabled,
  ariaLabel,
  ariaDisabled,
}: ButtonProps): JSX.Element => {
  if (id && id.length > 0) {
    return (
      <button
        className={
          className !== '' && className !== undefined
            ? className
            : 'primary-button'
        }
        id={id}
        onClick={onClick}
        type={type === 'button' || !type ? 'button' : 'submit'}
        disabled={disabled !== undefined ? disabled : false}
        aria-label={ariaLabel}
        aria-disabled={ariaDisabled}
        tabIndex={0}
      >
        {text}
      </button>
    );
  }
  return (
    <button
      className={
        className && className.length > 0 ? className : 'primary-button'
      }
      onClick={onClick}
      type={type === 'button' || !type ? 'button' : 'submit'}
      disabled={disabled !== undefined ? disabled : false}
      aria-label={ariaLabel}
      aria-disabled={ariaDisabled}
    >
      {text}
    </button>
  );
};

export default Button;
