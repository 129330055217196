import React from 'react';
import moment from 'moment';

type DateSeparatorProps = {
  date: string;
};

const DateSeparator = ({ date }: DateSeparatorProps): JSX.Element => (
  <div className="date-separator-container">
    <div className="date-separator-wing-left" />
    <div className="date-separator-pill">
      <div className="date-separator-time">{moment(date).format('MMMM Do YYYY')}</div>
    </div>
    <div className="date-separator-wing-right" />
  </div>
);

export default DateSeparator;
